import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom'; // Use React Router's Link
import Box from '@mui/material/Box';

const ResBreadcrumbs = ({ title, data }) => {
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsHidden(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    // Cleanup on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={`res-breadcrumbs ${isHidden ? 'hidden' : ''}`}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        style={{ paddingBottom: '10px' }}
      >
        {/* Left side: Title */}
        <Box display="flex" alignItems="center">
          {title && (
            <h2 style={{ color: '#1976d2', margin: 0 }}>
              {title}
            </h2>
          )}
        </Box>

        {/* Right side: Breadcrumbs */}
        <Box display="flex" alignItems="center">
          <Breadcrumbs aria-label="breadcrumb">
            {data.map((item, index) => (
              <Link
                key={index}
                to={item.link}
                style={{
                  fontSize: '18px',
                  fontWeight: '600',
                  textDecoration: 'none',
                  color: '#1976d2',
                }}
              >
                {item.title}
              </Link>
            ))}
            <Typography
              color="text.primary"
              style={{ fontSize: '18px' }}
            >
              {title}
            </Typography>
          </Breadcrumbs>
        </Box>
      </Box>
    </div>
  );
};

export default ResBreadcrumbs;
