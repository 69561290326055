import React, { useState } from "react";

const CustomDropdown = ({  options = [], onSelect }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);

    const handleSelect = (option, index) => {
        setSelectedIndex(index);

        const selectedData = {
            name: option.name,
            template: option.template,
            subject: option.subject
        };

        if (onSelect) {
            onSelect(selectedData);
        }

        setIsOpen(false);
    };

    return (
        <div className="custom-dropdown">
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="custom-dropdown-toggle"
            >
                {selectedIndex !== null ? options[selectedIndex].name : "Select a Template"}
            </button>
            {isOpen && (
                <div className="custom-dropdown-menu">
                    {options.length > 0 ? (
                          options.map((option, index) => (
                              <div
                                  key={index}
                                  className={`custom-dropdown-item ${
                                      selectedIndex === index ? "selected" : ""
                                  }`}
                                  onClick={() => handleSelect(option, index)}
                              >
                                  <span className="custom-radio">
                                      {selectedIndex === index && <span className="custom-radio-inner" />}
                                  </span>
                                  {option.name || "Unnamed Option"}
                              </div>
                          ))
                  ):( <div>No options available</div>)}
                </div>
            )}
        </div>
    );
};

export default CustomDropdown;
