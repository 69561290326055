import { Fragment } from 'react'

import HistoryReportBreadcrumbs from '../../components/Breadcrumbs'
import Jobtemplate from './jobTemplate'

const JobTemplate = () => {
    return (
        <Fragment>
            <HistoryReportBreadcrumbs title='Job Templates' data={[{ title: 'Campaign Dashboard' }]}/>
            <div>
                <Jobtemplate />
            </div>
        </Fragment>
    )
}

export default JobTemplate;
