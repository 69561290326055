import { Button, Card, CardContent, Grid, IconButton, TextField, Tooltip, Typography} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { DataGrid } from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import GlobalVariable from '../../path/global';
import { toast, Toaster } from 'react-hot-toast';
import CircularProgressWithLabel from '../../components/reusableComponents/CircularProgressWithLabel';
import DeleteIcon from '@mui/icons-material/Delete';


const baseURI = GlobalVariable.BASE_API_GOOGLE_URL;



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const JobNamesIn = () => {
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]); 
  const [job, setJobType] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredRows, setFilteredRows] = useState([]);
  const [selectedURLToDelete, setSelectedURLToDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isCircleLoading, setCircleIsLoading] = useState(false);
  const handleOnChange = (e) => {
    setJobType(e.target.value);
  };


  const ClickOpen = () => {
    setOpen(true);
    setJobType('');
    setSelectedURLToDelete(false);
    setDeleteId(null);
  }

  const handleClose = () => {
    setOpen(false);
    setJobType('');
    setSelectedURLToDelete(false);
    setDeleteId(null);
  };


  // const userDetails = JSON.parse(localStorage.getItem("userdetails"));
  // const username = userDetails.user.name
  // const userid = userDetails.user.id


  const fetchJobsData = async () => {
    setProgress(10)
    setCircleIsLoading(true)
    try{
      setProgress(40)
      setProgress(70)
      const response = await fetch(`${baseURI}job_analysis/businesstool/viewalljobs`, {
        method: 'GET'
      }); 
      
      if (response.ok) {
        const data = await response.json(); 
        setRows(data.details)
        setFilteredRows(data.details);
        setProgress(100)
        setCircleIsLoading(false)
      } else {
        console.error(`Error: ${response.status} - ${response.statusText}`);
      }
    }catch(error){

    }
  }
  useEffect(() => {
    fetchJobsData()
  },[])


  const handleDeleteClick = (row) => {
    console.log(row)
    setSelectedURLToDelete(true);
    setDeleteId(row.jobid);
    setOpen(true);
  };


  const handleSubmit = async () => {
    if (selectedURLToDelete) {
      setProgress(10)
      setCircleIsLoading(true)
      try {
        const response = await fetch(`${baseURI}job_analysis/businesstool/deljob`,{
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ jobid: deleteId }),
        });
        if (response.ok) {
          toast.success('Record deleted successfully.');
          handleClose();
          fetchJobsData(); 
          setProgress(100)
          setCircleIsLoading(false)
        } else {
          toast.error('Failed to delete the record.');
        }
      } catch (error) {
        toast.error('An error occurred while deleting the record.');
      }
    }else{
      setProgress(10)
      setCircleIsLoading(true)
      try {
        const response = await fetch(`${baseURI}job_analysis/businesstool/addjob`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ job }),
        });
        const data = await response.json();
  
        if (response.ok) {
          if(data.status_code === 200){
            toast.success(data.message);
            handleClose();
            fetchJobsData()
            setProgress(100)
            setCircleIsLoading(false)
          }
         
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        toast.error('An error occurred while adding the record.');
      }
    }
   
  };

  const NoRowsOverlay = () => (
    <Box sx={{ p: 2, textAlign: 'center', color: 'gray' }}>No records</Box>
  );




  // Data Columns
  const columns = [
  //   {field: 'urlid', headerName: 'Blocked CompanyId', flex: 2,headerClassName: 'bg-primary text-white text-size', renderCell: (params) => (
  //     <Tooltip title={<div style={{ fontSize: '14px'}}> {params.value }</div>} arrow>
  //       <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
  //         {params.value}
  //       </div>
  //     </Tooltip>
  // ) },
    {field: 'jobs',headerName: 'Job Type', flex: 2,headerClassName: 'bg-primary text-white text-size', renderCell: (params) => (
        <Tooltip title={<div style={{ fontSize: '14px'}}> {params.value }</div>} arrow>
          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {params.value}
          </div>
        </Tooltip>
    ) },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 3,
      renderCell: (params) => (
        <>
          
         
            <Tooltip title="Delete" arrow>
             <IconButton
                size="small"
                onClick={() => handleDeleteClick(params.row)}
                className='button-a'
              >
                <DeleteIcon color="primary" />
              </IconButton>
            </Tooltip>
         
        </>
      ),
      headerClassName: 'bg-primary text-white text-size',
    },
  ];



  const handleSearchChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = rows.filter((row) =>
      row.jobs.toLowerCase().includes(value)
    );
    setFilteredRows(filtered);
  };
  return (
    <>
      <div className='blocked-companies'>
      <Toaster position="top-right" reverseOrder={false} />
        <Card variant="outlined" sx={{ borderRadius: '10px' }}>
        {isCircleLoading ? (
        <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '70vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              zIndex: 10,
            }}
          >
            <CircularProgressWithLabel value={progress} />
            <Typography variant="body1" sx={{ marginTop: 2, color: 'text.secondary' }}>
              Loading...
            </Typography>
          </Box>
        ) : (
          <div className='jobextractor-table'>
            <Box>
              <CardContent style={{ paddingBottom: '10px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box className="JobExtractorView" alignItems="center" justifyContent="space-between">
                      <Typography variant='h5' sx={{ fontWeight: '600' }}></Typography>
                      <Box alignItems="center" className="dd" style={{display: 'flex'}}>

                      <TextField
                          label="Search"
                          variant="outlined"
                          size="small"
                          style={{ marginRight: '8px' }}
                          value={searchTerm}
                          onChange={handleSearchChange}
                        />
                        
                        <Tooltip title="ADD JOB TYPE">
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: '8px', width: '250px' }}
                            className='button-t button-j'
                            onClick={ClickOpen}
                          >
                            ADD Job Type
                          </Button>
                        </Tooltip>
                      </Box>
                    
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                  <DataGrid
                    rows={filteredRows}
                    columns={columns}
                    getRowId={(row) => row.jobid}
                    className="job-extractor-table"
                    disableRowSelectionOnClick
                    slots={{ noRowsOverlay: NoRowsOverlay }}
                    slotProps={{
                      noRowsOverlay: {
                        sx: { fontSize: '16px', fontWeight: 'bold', color: 'gray' },
                      },
                    }}
                    initialState={{
                      pagination: {
                        paginationModel: { pageSize: 10, page: 0 },
                      },
                    }}
                    pageSizeOptions={[10, 25, { value: -1, label: 'All' }]}
                  />
                  </Grid>
                </Grid>
              </CardContent>

              <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={(event, reason) => {
                  if (reason !== "backdropClick") handleClose();
                }}
                aria-describedby="alert-dialog-slide-description"
                fullWidth
                maxWidth="sm"
                className="job-extractor"
                disableEscapeKeyDown
              >
                <DialogTitle>
                  <Typography variant='h6' className='dialog-title bottom-line'>  {selectedURLToDelete ? 'Delete Confirmation' : 'Add Job Type '}</Typography>
                  <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                    className='bg-primary'
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                {selectedURLToDelete ? (
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure you want to delete this record?
                <Box sx={{ marginTop: '20px', textAlign: 'right' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    sx={{ marginRight: '8px' }}
                  >
                    Confirm
                  </Button>
                  <Button variant="contained" color='error' onClick={handleClose}>
                    Cancel
                  </Button>
                </Box>
            </DialogContentText>
      ) : (
                  <DialogContentText id="alert-dialog-slide-description">
                    <Box id="drop-area" 
                      sx={{
                      padding: '20px',
                      textAlign: 'center',
                      borderRadius: '5px',
                      marginBottom: '20px'
                    }}>
                      <Box sx={{marginBottom:'10px'}}>
                        <TextField
                          type='text'
                          label='Add job type'
                          placeholder='Add job type '
                          fullWidth
                          value={job}
                          onChange={handleOnChange}
                        >
                        </TextField>
                      </Box>
                      <Box>
                        <label htmlFor="file-upload">
                          <Button variant="contained" component="span" onClick={handleSubmit}>Submit</Button>
                        </label>
                      </Box>
                    </Box>
                  </DialogContentText>
      )}
                </DialogContent>
              </Dialog>
            </Box>
          </div>
        )}
        </Card>
      </div>
    </>
  );
}

export default JobNamesIn;
