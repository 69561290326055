import React, {Fragment} from 'react';
import ResBreadcrumbs from '../../components/Breadcrumbs'
import EmailCampaign from './emailCampaign';

const EmailCampaignIn = () => {
    return(<>
       
        <Fragment>
        <ResBreadcrumbs title='Email Campaign' data={[{ title: 'Dashboard' }]}/>
            <div className='app-email-campaign'>
                <EmailCampaign/>
            </div>
              
        </Fragment>
    </>);
}

export default EmailCampaignIn
