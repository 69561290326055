import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import Pagination from '@mui/material/Pagination';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteUserModal from './UserDelete'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import GlobalVariable from '../../path/global';
import { Link, useLocation, useNavigate  } from 'react-router-dom';
import axios from 'axios';
import Loader from '../../components/Loader/SpLoader';
import CircularLoader from '../../components/Loader/circularloader';
import toast, {Toaster} from "react-hot-toast"

const UsersPage = ({openSnackbar}) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesToShow, setEntriesToShow] = useState(10); // Initial value is set to 10
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [selectedUserToDelete, setSelectedUserToDelete] = useState(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [searchData, setSearchData] = useState([]) 
  const [rowsData, setRowsData] = useState([]);
  const [open, setOpen] = useState(false);
 
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const baseApiUrl = GlobalVariable.BASE_API_URL

  const userDetails = JSON.parse(localStorage.getItem('userdetails'));
  const companyId = userDetails.user.companyId;
  
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // const getRoleName = (roleId) => {
  //   const role = roles.find((r) => r.id === roleId);
  //   return role ? role.roleName : 'Unknown Role';
  // };
  const location = useLocation();
  useEffect(()=>{
    if (location.state?.message) {
      // Show the toast message
      const { message, severity } = location.state;
      if (severity === 'success') {
        toast.success(message);
      } else if (severity === 'error') {
        toast.error(message);
      }
    }
  },[location])
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${baseApiUrl}getTotUsers`);
      const usersData = response.data.activeData;
    
      const rolesData = await axios.get(`${baseApiUrl}getRoles`);
      
      const usersDat = usersData.map(user => {
        let totToken = 0;
        let totCost = 0;
    
        const profileMatching = user.profileMatchData || [];
    
        profileMatching.forEach(match => {
          const totalTokenUsed = parseInt(match.totalTokenUsed)
          const totalCost = parseFloat(match.totalCost) 
    
          totToken += totalTokenUsed;
          totCost += totalCost;
        });
    
        return {
          id: user.id,
          name: user.name,
          email: user.email,
          roleName: rolesData.data.activeData.find(role => role.id === user.roleId)?.roleName || 'Unknown Role',
          changePrompt: user.changePrompt === 1 ? 'True' : 'False',
          totalTokenUsed: totToken.toLocaleString('en-US'),
          totalResumeParsed: profileMatching.length,
          totalCost: '$' + totCost.toFixed(2)
        };
      });
    
      setRowsData(usersDat);
      setSearchData(usersDat);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false); 
    }
  };
  useEffect(() => {
    fetchData();

  }, []);
  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    { field: 'name', headerName: 'User Name', flex: 1, headerClassName: 'bg-primary text-white text-size', renderCell: (params) => (
      <Tooltip title={<div style={{ fontSize: '14px'}}> {params.value }</div>} arrow>
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.value}
        </div>
      </Tooltip>
    ) },
    { field: 'email', headerName: 'Email', flex: 1, headerClassName: 'bg-primary text-white text-size' , renderCell: (params) => (
      <Tooltip title={<div style={{ fontSize: '14px'}}> {params.value }</div>} arrow>
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.value}
        </div>
      </Tooltip>
    )},
    { field: 'roleName', headerName: 'Role', flex: 1, headerClassName: 'bg-primary text-white text-size'},
    { field: 'changePrompt', headerName: 'Change Prompt', flex: 1, headerClassName: 'bg-primary text-white text-size' },
    { field: 'totalTokenUsed', headerName: 'Total Tokens Used', headerClassName: 'bg-primary text-white text-size table-width', width: 200 },
    { field: 'totalResumeParsed', headerName: 'Total Resumes Parsed', headerClassName: 'bg-primary text-white text-size table-width', width: 200 },
    { field: 'totalCost', headerName: 'Total Price', flex: 1, headerClassName: 'bg-primary text-white text-size' },

    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => (
        <>
          <Tooltip title="Edit" arrow>
            <IconButton
              size="small"
              style={{ marginRight: '8px' }}
              // onClick={() => handleEditClick(params.row)}
              id={`users-${params.row.id}`} tag={Link}  
              className='button-a'
            >
              <Link to={`/users/edit-user/${btoa(String(params.row.id))}`}>
                <EditIcon color="primary" />
              </Link>
              
            </IconButton>
          </Tooltip>
          {isSmallScreen ? (
            <Tooltip title="Delete" arrow>
             <IconButton
                size="small"
                onClick={() => handleDeleteClick(params.row.id)}
                className='button-a'
              >
                <DeleteIcon color="primary" />
              </IconButton>
            </Tooltip>
          ) : (
            <IconButton
              size="small"
              onClick={() => handleDeleteClick(params.row.id)}
              className='button-a'
            
            >
              <DeleteIcon color="primary" />
            </IconButton>
          )}
        </>
      ),
      headerClassName: 'bg-primary text-white text-size',
    },
  ];

  const getRowId = (row) => row.email;

  const filteredRows = rowsData.filter(
    (row) =>
      (row.name &&
        row.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (row.email &&
        row.email.toLowerCase().includes(searchTerm.toLowerCase())) || 
      (row.roleName &&
        row.roleName.toLowerCase().includes(searchTerm.toLowerCase())) || 
      (row.changePrompt &&
        row.changePrompt.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const CustomPagination = () => (
    <div style={{width:'100%', justifyContent: 'space-between', alignItems: 'center' , display: 'flex' }} className='userPageView'>
      <div style={{ margin:'8px 0 0 8px', width: '100%' }}>
      Showing {Math.min((currentPage - 1) * entriesToShow + 1, rowsData.length)} to {Math.min(currentPage * entriesToShow, rowsData.length)} of {rowsData.length} entries
      </div>
      <div style={{ display: 'flex', alignItems: 'center', }}>
        <Typography style={{ marginRight: '8px' }}>Rows</Typography>
        <TextField
          select
          variant="outlined"
          size="small"
          value={entriesToShow}
          onChange={(e) => setEntriesToShow(e.target.value)}
          style={{ width: '160px' }} // Reduced the width
        >
          {[5, 10, 25, 50, 100].map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        {/* <Typography style={{ margin: '0 8px' }}>Entries</Typography> */}
        <Pagination
          color="primary"
          count={Math.ceil(rowsData.length / entriesToShow)}
          page={currentPage}
          onChange={(event, value) => setCurrentPage(value)}
          sx={{width: '100%'}}
        />
      </div>
    </div>
  );

  const handleFilter = (e) => {
    setSearchTerm(e);
    let data = searchData
    let filterit = filteritems(data,e)
    setRowsData(filterit)
    setCurrentPage(1)
   
  };
  const filteritems = (array, search) => {
    const lowSearch = search.toLowerCase()
    return array.filter(function (wine) {
        return Object.values(wine).some(val => String(val).toLowerCase().includes(lowSearch)
        )
    })
}



  const handleDeleteClick = (id) => {
    setSelectedUserToDelete(id);
    setDeleteModalOpen(true);
    setOpen(true);
  };
  

const handleDelete = async () => {
  setIsLoading(true)
  try {
    // Perform API call to delete the user
    const response = await axios.put(`${baseApiUrl}DeActiveUser/${selectedUserToDelete}`);

    // Check if the deletion was successful
    if (response.status === 200) {
      // Call onDelete callback to handle deletion in parent component
      openSnackbar('success', "User Deleted Successfully");
      setOpen(false)
      fetchData();
      // onDelete(id);
    } else {
      // Handle error condition
      console.error('Failed to delete user:', response.data);
      openSnackbar('danger', "Internal Error");
      setOpen(false);

    }
  } catch (error) {
    // Handle API call error
    console.error('Error deleting user:', error);
    setOpen(false);
  }
}
  return (
    <div>
      <Toaster position="top-right" reverseOrder={false} />
      <Card variant="outlined" sx={{borderRadius: '10px'}}>
      
        <div className='users-table'>
            <Box>
              <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box className='userView'  alignItems="center" justifyContent="space-between">
                        <Box>
                          <Typography variant='h5' sx={{ fontWeight: '600' }}>
                            User List
                          </Typography>
                        </Box>

                        <Box className='userView1'   alignItems="center">
                          <Grid item>
                              <TextField
                                label="Search"
                                variant="outlined"
                                size="small"
                                value={searchTerm}
                                onChange={(e) => handleFilter(e.target.value)}
                                style={{ marginRight: '8px' }}
                              />
                          </Grid>
                          <Grid item>
                          <Link to="/users/add-user" style={{ textDecoration: 'none' }}>
                            <Tooltip title="Add User" arrow>
                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                style={{ marginLeft: '8px' }}
                                className='button-t'
                                onClick={() => navigate('/add-user')}
                              >
                                Add User
                              </Button>
                            </Tooltip>
                            </Link>
                          </Grid>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <div style={{ width: '100%'}}>
                      {/* {isLoading && <Loader />} */}
                        <DataGrid
                         rows={rowsData.slice((currentPage - 1) * entriesToShow, currentPage * entriesToShow)}
                          // rows={filteredRows}
                          columns={columns}
                          pageSize={entriesToShow}
                          autoHeight
                          getRowId={getRowId}
                          disableRowSelectionOnClick
                          disableColumnMenu
                          pagination
                          sx={{position: 'relative'}}
                          paginationMode="server"
                          onPageChange={(params) => setCurrentPage(params.page)}
                         
                          components={{
                            
                            NoRowsOverlay: () => (
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', background: 'rgba(255, 255, 255, 0.8)' }}>
                              {!isLoading && rowsData.length === 0 ? (
                                    <Typography>No data available</Typography>
                                  ) : (
                                    isLoading && <Loader/>
                                  )}
                            </div>
                            ),
                            Pagination: CustomPagination,
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
              </CardContent>
              
            </Box>
        </div>
      </Card>
     <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle style={{ backgroundColor: '#4880FF', color: 'white', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px' }}>
        Delete User
        {/* <IconButton onClick={handleClose} style={{ color: 'white' }}>
          <CloseIcon />
        </IconButton> */}
      </DialogTitle>
      <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center', background: 'rgba(255,255,255,0.5)', left: '50%', top: '50%' }}>
            {isLoading && <CircularLoader />}
          </div>
      <DialogContent style={{ padding: '16px' }}>
        <p style={{ marginBottom: '16px' }}>Are you sure you want to delete the user?</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained">
          Cancel
        </Button>
        <Button onClick={handleDelete} color="primary" variant="contained">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
    </div>
  );
};

export default UsersPage;
