// ** User List Component
import { Fragment, useState, useEffect } from 'react'
import { IconButton, InputAdornment, TextField,Input } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
// import SearchIcon from '@material-ui/icons/Search';
// import ClearIcon from '@material-ui/icons/Clear';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { CardContent, FormControl } from '@mui/material';
import Typography from '@mui/material/Typography';
import ResBreadcrumbs from '../../components/Breadcrumbs'
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useForm } from 'react-hook-form'
import GlobalVariable from '../../path/global'
import axios from 'axios';
import SelectDropDown from '../../components/reusableComponents/selectDropDown';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button } from '@mui/material';
import Tooltip from '@material-ui/core/Tooltip';
import MySnackbarContent from '@material-ui/core/SnackbarContent';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Loader from '../../components/Loader/SpLoader';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgressWithLabel from '../../components/reusableComponents/CircularProgressWithLabel';
const baseApiUrl = GlobalVariable.BASE_API_URL


const EditUserList = (props) => {

  const navigate = useNavigate();

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  // const [editedRow, setEditedRow] = useState(null);
  // const [editedValues, setEditedValues] = useState({});
  const [editRow, setEditRow] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [password, setPassword] = useState('')
  const [selectedRole, setSelectedRole] = useState('');
  const [roles, setRoles] = useState([]);
  const [phone, setPhone] = useState("");
  const [ofcPosition, setofcPosition] = useState("");
  const [seamlessEmail, setSeamlessEmail] = useState("");
  const [changePrompts, setChangePrompts] = useState(false);
  const [runCampaign, setRunCampaign] = useState(false);
  // const [userPermissions, setUserPermissions] = useState(false);
  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [numValid, setNumValid] = useState(false);
  const [prptName, setPromptName] = useState('');
  const [prptDescription, setPromptDescription] = useState('');
  const [updateId, setUpdateId] = useState('');
  const [filterText, setFilterText] = useState('');
  // const [nextSNO, setNextSNO] = useState(1);
  // const [toastMessage, setToastMessage] = useState(false);
  const [canCheck, setCanCheck] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isCircleLoading, setCircleIsLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); 
  const [progress, setProgress] = useState(0);


  const { id } = useParams();

  const [decodedId, setDecodedId] = useState('')


  const {
    register,
    handleSubmit,setValue,
    formState: { errors },
    clearErrors
  } = useForm({defaultValues: {
    name: "",
    email: "",
    phone: "",
    ofcPosition: "",
    seamlessEmail: "",
  },})

  useEffect(() => {
    const decoded = atob(id)
    setDecodedId(decoded)
  }, [id])

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return; // If the user clicks away from the Snackbar, do nothing
    }
    setOpenSnackbar(false); // Close the Snackbar by updating the state variable
  };
  useEffect( () => {
    fetchData()
  },[decodedId])


  const fetchData = async () => {
    setIsLoading(true)
    setProgress(10);
    setCircleIsLoading(true)

    try {
      setProgress(50);
      const response = await axios.get(`${baseApiUrl}getRoles`);
      const rolesData = response.data.activeData
      const rolesDat = rolesData.map(item => ({
        value: item.id,
        label: item.roleName
      }))
      setRoles(rolesDat); 
      getSingle(rolesDat)
      setTimeout(() => {
        setProgress(70);
        setTimeout(() => {
          setProgress(100);
          setTimeout(() => {          
            setCircleIsLoading(false);
            setIsLoading(false);
          }, 300);
        }, 500);
      },800);
      // Assuming the result is an array of objects with id and name fields
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  
};

const getSingle = async (data) => {

  try {

    const response = await axios.get(`${baseApiUrl}getSinngleUsers/${decodedId}`);


    if (response && response.data.activeData.length > 0) {
      const userData = response.data.activeData[0];

      const role = data.find((role) => role.value === userData.roleId);
      const roleNameD = role ? role.value : '';

      setName(userData.name);
      setEmail(userData.email);
      setPassword(userData.password);
      setSelectedRole(roleNameD);
      setItems(userData.propmtsData);
      setCanCheck(userData.changePrompt);
      setChangePrompts(userData.changePrompt === 1);
      setRunCampaign(userData.run_campaign === 1);
      // setUserPermissions(userData.user_permission === 3);

      setValue('name', userData.name);
      setValue('email', userData.email);
  
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
  } finally {

  }
};


  // useEffect(() => {
   
  
  //   fetchData();
  // }, [decodedId]);


  const userDetails = JSON.parse(localStorage.getItem('userdetails'));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = () => {
    if (filterText.trim() === '') {
      // getData();
    } else {
      const escapedFilterText = filterText.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
      const filterRegex = new RegExp(escapedFilterText, 'i');

      const filteredItems = items.filter((item) => {
        const nameMatches = item.languageName && filterRegex.test(item.languageName);
        const daysMatches = item.languageDays && (filterRegex.test(item.languageDays) || item.languageDays.toString().includes(filterText));
        return nameMatches || daysMatches;
      });

      setItems(filteredItems);

    }
  };
  const totalPages = Math.ceil(10 / rowsPerPage);


  const handleSearchClear = () => {
    // getData();
    setFilterText('');
  };

  const handleEdit = (rowId) => {
    const selectedItem = items.find((item) => item.id === rowId);

    setEditRow(rowId);
    setUpdateId(rowId);
    setPromptName(selectedItem.promptName);
    setPromptDescription(selectedItem.promptDescription);
  };
  const handleFilterChange = (event) => {
    setFilterText(event.target.value);
  };
  // const handleDelete = (itemId) => {
  //   const confirmDelete = window.confirm('Are you sure you want to delete?');

  //   if (confirmDelete) {

  //     const config = {
  //       headers: { Authorization: userDetails.token },
  //     };

  //     // axios
  //     //   .put(baseApiUrl + `v1/tegremove/${itemId}`, null, config)
  //     //   .then((response) => {
  //     //     console.log('delete res ====>', response);
  //     //     setToastMessage(true);
  //     //     getData();
  //     //   })
  //     //   .catch((error) => {
  //     //     console.error('There was an error!', error);
  //     //   });
  //   }
  // };
  const handleNameChange = (e) => {
    const { value } = e.target
    setName(value)
  }

  const handleEmailChange = (e) => {
    const { value } = e.target
    setEmail(value)
  }

  const handlePasswordChange = (e) => {
    const { value } = e.target
    setPassword(value)
  }
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleChange = async (event) => {
    setSelectedRole(event.value);
    let n = event.value


    try {
      const promptData = await axios.get(`${baseApiUrl}getRolePrompts/${n}`);


      setItems(promptData.data.activeData)

      // setPromptRows(prompts)
    } catch (error) {
      console.error('Error fetching prompt data:', error);
    }
  };
  const handlePhoneChange = (e) => {
    const { value } = e.target;
    setPhone(value);
    setValue('phone', e.target.value);
    clearErrors('phone');
  };

  const handlePositionChange = (e) => {
    const { value } = e.target;
    setofcPosition(value);
    setValue('ofcPosition', e.target.value);
    clearErrors('ofcPosition');
  };
  
  const handleSeamlessEmailChange = (e) => {
    const { value } = e.target;
    setSeamlessEmail(value);
    setValue('seamlessEmail', e.target.value);
    clearErrors('seamlessEmail');
  };




  const onSubmit = async () => {
    setIsLoading(true);

    setIsSubmitDisabled(true)
    const editedPrompts = items.map((row) => ({
      id:row.id,
      promptName: row.promptName,      
      promptDescription: row.promptDescription,
    }));
    const companyId = userDetails.user.companyId
    const users = {
      id : Number(decodedId),
      name, email, 
      roleId: selectedRole, 
      companyId: companyId,
      prompts: editedPrompts,
      changePrompt: changePrompts ? 1 : 0,
      run_campaign: runCampaign ? 1 : 0,
      // user_permission: userPermissions ? 3 : 0

    };

    await axios.put(`${baseApiUrl}updateUser/${users.id}`,  users )
      .then(response => {

        if (response.status === 200) {
          navigate('/users',{
            state: {
              message: 'User Data Successfully Updated',
              severity: 'success',
            },
          });

          setOpenSnackbar(true);
          setIsLoading(false);

        }
      }).catch(error => {
          let message = 'An error occurred';
          let severity = 'error';

          if (error.response?.data?.status_code === 401) {
            message = 'User unauthorized';
          } else if (error.response?.data?.status_code === 422) {
            message = 'Data Object not correct';
          }

          navigate('/users', {
            state: {
              message,
              severity,
            },
          });
      }).finally(() => {
        setIsSubmitDisabled(false); // Enable the submit button after login attempt
      });


  }

  const UpdateSave = (id) => {
    setNumValid(false);
    setOpen(false);

    const reg = new RegExp('^[0-9]+$');
    const res = /^[a-zA-Z]+$/;

    if (prptName === '') {
      // Handle the case when prptName is empty
    } else if (prptDescription === '') {
      // Handle the case when prptDescription is empty
    } else if (reg.test(prptName) === true) {
      // setOpen(true);
    } else if (res.test(prptDescription) === true) {
      // setOpen(true);
      // setNumValid(true);
    } else {
      const updatedItems = items.map((item) =>
        item.id === id
          ? {
            ...item,
            promptName: prptName,
            promptDescription: prptDescription,
          }
          : item
      );

      setItems(updatedItems);


      setPromptDescription('');
      setPromptName('');
      setEditRow('');
      setUpdateId('');
    }
  };


  const UpdateCancel = () => {
    setEditRow('');
    setPromptDescription('');
    setPromptName('');
  };

  const handleCheck = (setter) => (event) => {
    setter(event.target.checked);
  };
  const filteredData = []
  // items.filter((item) => {
  //   const nameMatches = item.languageName.toLowerCase().includes(filterText.toLowerCase());
  //   const daysMatches = item.languageDays.toLowerCase().includes(filterText.toLowerCase());
  //   return nameMatches || daysMatches;
  // });
  return (
    <Fragment>
      <ResBreadcrumbs title='Users ' data={[{ title: 'Dashboard' }]} />

      <div className='app-user-list' style={{ position: 'relative' }}>
      <div style={{position: 'absolute', top: '50%', left: '50%', background: 'rgba(255,255,255, 0.5)', zIndex: '1025'}}>
       
        </div>
        {isCircleLoading ?(
                <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '70vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              zIndex: 10,
            }}
          >
            <CircularProgressWithLabel value={progress} />
            <Typography variant="body1" sx={{ marginTop: 2, color: 'text.secondary' }}>
              Loading...
            </Typography>
          </Box>
            ) : (
        <Card>
          <Box sx={{ padding: '10px 15px' }}>
            <Typography fontSize={20} fontWeight={700}>Edit User</Typography>
          </Box>

         
           
                 <CardContent>
                 <Box component={'form'} onSubmit={handleSubmit(onSubmit)} noValidate sx={{ marginBottom: '50px' }}>
                   <Grid container direction="column" spacing={2} alignItems={'center'} sx={{ padding: '0px 0px 16px ' }}>
                    <Grid container spacing={2} paddingLeft={'15px'}>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                       <TextField
                         margin="normal"
                         required
                         fullWidth
                         sm={12}
                         id="name"
                         label="Name"
                         name="name"
                         {...register('name', {
                           required: 'Name is required'
                         })}
                         error={Boolean(errors.name)}
                         helperText={errors.name?.message}
                         value={name}
                         onChange={(e) => {
                           handleNameChange(e)
                         }}
                         autoComplete="Off"
                         autoFocus
                       />
                     </Grid>
                     <Grid item xs={12} sm={4} md={4} lg={4}>
                       <TextField
                         margin="normal"
                         required
                         fullWidth
                         sm={12}
                         id="email"
                         label="Email"
                         name="email"
                         {...register('email', {
                           required: 'Email is required'
                         })}
                         error={Boolean(errors.email)}
                         helperText={errors.email?.message}
                         value={email}
                         onChange={(e) => {
                           handleEmailChange(e)
                         }}
                         autoComplete="Off"
                         className='demo-simple-select-label'
                         InputProps={{ readOnly: true }}
                       />
                     </Grid>
                     {/* <Grid item xs={12} sm={3} md={3}>
                       <TextField
                         margin="normal"
                         required
                         fullWidth
                         type={showPassword ? "text" : "password"}
                         name="password"
                         value={password}
                         label="Password"
                         {...register('password', {
                           required: 'Password is required',
                           minLength: {
                             value: 6,
                             message: 'Password must be at least 6 characters',
                           },
                         })}
                         error={Boolean(errors.password)}
                         helperText={errors.password?.message}
                         onChange={(e) => {
                           handlePasswordChange(e)
                         }}
                         InputProps={{
                           endAdornment: (
                             <InputAdornment position="end">
                               <IconButton
                                 aria-label="toggle password visibility"
                                 onClick={handleClickShowPassword}
                                 edge="end"
                               >
                                 {showPassword ? <VisibilityOff /> : <Visibility />}
                               </IconButton>
                             </InputAdornment>
                           ),
                         }}
                         id="password"
                         autoComplete="current-password"
                       />
                     </Grid> */}
                     <Grid item xs={12} sm={4} md={4} lg={4}>
                       <FormControl fullWidth sx={{ top: '15px' }}>
                         <SelectDropDown valueData={roles} value={selectedRole} onJobChange={handleChange} placeholder="Select Role" />
                       </FormControl>
                     </Grid>
                    </Grid>
                    
     
                    
     
                   </Grid>
     
     
                   <Grid container spacing={2}>
                     <Grid item xs={12} sm={12}>
                       <div style={{ position: 'relative', width: '100%', marginBottom: '50px' }}>
                         <Table >
                           <div>
     
                             <div>
                               {/* <FormControl>
                                 <Input
                                   id="search_filter"
                                   type="text"
                                   placeholder="Search"
                                   value={filterText}
                                   onChange={(e) => this.setState({ filterText: e.target.value })
                                   }
                                   style={{ borderRadius: '50px' }}
                                   endAdornment={(
                                     <InputAdornment position="end">
                                       <IconButton
                                         aria-label="Search filter"
                                         onClick={handleSearch}
                                         style={{ paddingTop: '10px', marginRight: '10px' }}
                                       >
                                         <SearchIcon />
                                       </IconButton>
                                       {filterText && ( // Render the clear icon only when filterText has a value
                                         <IconButton
                                           aria-label="Clear search"
                                           onClick={handleSearchClear}
                                           style={{ paddingTop: '10px', marginRight: '10px' }}
                                         >
                                           <ClearIcon />
                                         </IconButton>
                                       )}
                                     </InputAdornment>
                                   )}
                                 />
                               </FormControl> */}
                             </div>
                           </div>
                           <Table className='adduser_table'>
                             <TableHead>
                               <TableRow className='internshiptable' >
                                 <TableCell sx={{ width: '15%' }}>
                                   Prompt Name
                                 </TableCell>
                                 <TableCell >Prompt Description</TableCell>
                                 <TableCell sx={{ width: '8%' }}>
                                   Action
                                 </TableCell>
                               </TableRow>
                             </TableHead>
                             <TableBody>
                               {(filteredData.length === 0 ? items : filteredData)
                                 .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                 .filter(
                                   (row) =>
                                     !filterText.length ||
                                     row.languageName
                                       .toString()
                                       .toLowerCase()
                                       .includes(filterText.toString().toLowerCase())
                                 )
                                 .map((item, index) => (
                                   <TableRow key={item.id}>
                                     <TableCell style={{ padding: '0px', paddingLeft: 15 }}>
                                       {editRow === item.id ? (
                                         <TextField
                                           error={prptName === ''}
                                           id="outlined-error"
                                           helperText={prptName === '' ? 'Please enter name.' : ''}
                                           defaultValue={item.promptName}
                                           value={prptName}
                                           onChange={(e) => setPromptName(e.target.value)}
                                           className='input'
                                         />
                                       ) : (
                                         item.promptName
                                       )}
                                     </TableCell>
                                     <TableCell style={{ padding: '0px', paddingLeft: 10 }}>
                                       {editRow === item.id ? (
                                         <TextField
                                           error={prptDescription === ''}
                                           id="outlined-error"
                                           helperText={prptDescription === '' ? 'Please enter days.' : ''}
                                           defaultValue={item.promptDescription}
                                           value={prptDescription}
                                           onChange={(e) => setPromptDescription(e.target.value)}
                                           className='input input-jobDes'
                                         />
                                       ) : (
                                         item.promptDescription
                                       )}
                                     </TableCell>
                                     <TableCell style={{ padding: '0px' }}>
                                       {editRow === item.id ? (
                                         <>
                                           <Tooltip title="Save">
                                             <IconButton onClick={() => UpdateSave(item.id)} className='button-a'>
                                               <CheckCircleIcon />
                                             </IconButton>
                                           </Tooltip>
                                           <IconButton onClick={UpdateCancel} className='button-a'>
                                             <CloseIcon />
                                           </IconButton>
                                         </>
                                       ) : (
                                         <>
                                           <IconButton onClick={() => handleEdit(item.id)} className='button-a'>
                                             <EditIcon />
                                           </IconButton>
                                           {/* <IconButton onClick={() => handleDelete(item.id)} className='button-a'>
                                             <DeleteIcon />
                                           </IconButton> */}
                                         </>
                                       )}
                                     </TableCell>
                                   </TableRow>
                                 ))}
                             </TableBody>
                           </Table>
                           {items.length === 0 ? (
                             <Table>
                               <p
                                 style={{
                                   alignItems: 'center',
                                   width: '100%',
                                   justifyContent: 'center',
                                   display: 'flex',
                                   padding: '10px 0px'
                                 }}
                               >
                            {!isLoading && items.length === 0 ? (
                                         <Typography>No data available</Typography>
                                       ) : (
                                         isLoading && <Loader/>
                                       )}
                               </p>
                             </Table>
                           ) : null}
                         </Table>
                         {/* <TablePagination
                           rowsPerPageOptions={[5, 10, 25]}
                           component="div"
                           count={!filterText.length ? items.length : totalPages}
                           rowsPerPage={rowsPerPage}
                           page={page}
                           onChangePage={handleChangePage}
                           onChangeRowsPerPage={handleChangeRowsPerPage}
                         /> */}
                         {/* {toastMessage && (
                                               <Snackbar
                                                 anchorOrigin={{
                                                   vertical: 'top',
                                                   horizontal: 'right',
                                                 }}
                                                 open={toastMessage}
                                                 autoHideDuration={6000}
                                               // onClose={handletoastCloseMessage}
                                               >
                                                 <MySnackbarContent
                                                   // onClose={handletoastCloseMessage}
                                                   variant="elevation"
                                                   message={this.state.notificationMessage}
                                                 />
                                               </Snackbar>
                                             )} */}
                       </div>
                     </Grid>
                   </Grid>
                   <Grid container justifyContent={"flex-end"} alignItems={"center"}>
                   
                     <div>
                       <FormGroup>
                         <FormControlLabel
                           control={<Checkbox />}
                           onChange={handleCheck(setChangePrompts)}
                           checked={changePrompts}
                           label=" Change Prompts?"
                         />
                       </FormGroup>
                     </div>
                     <div>
                       <FormGroup>
                         <FormControlLabel
                           control={<Checkbox />}
                           checked={runCampaign}
                           onChange={handleCheck(setRunCampaign)}
                           label=" Run Campaign?"
                         />
                       </FormGroup>
                     </div>
                     {/* <div>
                       <FormGroup>
                         <FormControlLabel
                           control={<Checkbox />}
                           checked={userPermissions}
                           onChange={handleCheck(setUserPermissions)}
                           label=" User Permissions?"
                         />
                       </FormGroup>
                     </div> */}
                     <Button
                       color="primary"
                       type="submit"
                       disabled={isSubmitDisabled}
                       className="bg-primary"
                       sx={{ marginTop: "10px" }}
                     >
                       Update User
                     </Button>
                   </Grid>
     
                 </Box>
               </CardContent>
          
     

        </Card>
   )}
      </div>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </Fragment>
  )
}

export default EditUserList
