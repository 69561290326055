import { Fragment } from 'react'

import HistoryReportBreadcrumbs from '../../components/Breadcrumbs'
import UnsubscribedEmailIn from './unsubscribedEmail'

const UnsubscribedEmail = () => {
    return (
        <Fragment>
            <HistoryReportBreadcrumbs title=' Unsubscribed Email Size' data={[{ title: 'Campaign Dashboard' }]}/>
            <div>
                <UnsubscribedEmailIn />
            </div>
        </Fragment>
    )
}

export default UnsubscribedEmail;
