import { Fragment } from 'react'

import HistoryReportBreadcrumbs from '../../components/Breadcrumbs'
import BlockedIndustriesIn from './blockedIndustries'

const BlockedIndustries = () => {
    return (
        <Fragment>
            <HistoryReportBreadcrumbs title='Blocked Industries' data={[{link: '/campaign-dashboard',  title: 'Campaign Dashboard' }]}/>
            <div>
                <BlockedIndustriesIn />
            </div>
        </Fragment>
    )
}

export default BlockedIndustries;
