import React, {useState} from 'react';
import { styled, useTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SideMenu  from '../SideBar/SideMenuList';
import Tooltip from '@mui/material/Tooltip';
// import SearchInput from '../reusableComponents/SearchInput';
import { useNavigate } from 'react-router-dom';
// import InputAdornment from '@mui/material/InputAdornment';
// import { Link } from 'react-router-dom'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
// import Badge from '@mui/material/Badge';
// import SearchIcon from '@mui/icons-material/Search';
// import CircleNotificationsOutlinedIcon from '@mui/icons-material/CircleNotificationsOutlined';
// import Avatar from '@mui/material/Avatar';
// import Menu from '@mui/material/Menu';
import AppRoutes from '../../routes/approutes/AppRoutes';
import { useMediaQuery } from '@mui/material';
// import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
const drawerWidth = 240;

// Define the custom styles for IconButton
const CustomIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: '#fff', // Replace with your desired color
  '&:hover': {
    backgroundColor: '#fff', // Replace with your desired hover color
  },
  cursor: 'pointer'
}));



const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  height: '75px',
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showJobAnalysis, setShowJobAnalysis] = useState(false); // State to control visibility
  const [isLoading, setIsLoading] = useState(false);
  const data = localStorage.getItem('userdetails');
  const parseData = JSON.parse(data);

  const handleLogout = () => {
    setIsLoading(true)
    localStorage.removeItem('userdetails');
        // window.location.reload();
       navigate('/')
      setIsLoading(false)
  };

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
    setShowJobAnalysis(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleProfileClick = () => {
    handleClose(); // Close the profile menu
    navigate('/profile'); // Navigate to the profile page
  };

  const handleProfileMatchingClick = () => {
    // Handle profile matching functionality
    if (isMobileView) {
      handleDrawerClose(); // Close the drawer in mobile view
    }
  };

  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

  const [move, setMove] = React.useState(!isMobileView); // Drawer is open by default on larger screens

  React.useEffect(() => {
    setOpen(!isMobileView);
    setShowJobAnalysis(isMobileView); // Set the drawer state based on the screen size
  }, [isMobileView]);

  return (
    <ThemeProvider theme={theme}>
       {isLoading && (
        <div className="full-page-loader">
          <CircularProgress size={100} />
        </div>
      )}
          <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {isMobileView && (
              <CustomIconButton
                className='Icon-bg-primary-white'
                aria-label="open drawer"
                onClick={() => {
                  setMove(!move)
                  handleDrawerOpen();
                  setShowJobAnalysis(false);
                }}
                edge="start"
                sx={{ mr: 2, ...(open && { display: 'none' }), background: '#fff' }}
              >
                <MenuIcon color="primary" />
              </CustomIconButton>
            )}
            <CustomIconButton
              aria-label="open drawer"
              onClick={() => {
                // setMove(!move)
                handleDrawerOpen();
                setShowJobAnalysis(false);
              }}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }), ...(isMobileView && { display: 'none' }) }} // Hide on mobile and when drawer is open
            >
              <MenuIcon color="primary" />
            </CustomIconButton>
          </Box>
          
            {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <SearchInput
                placeholder="Search"
                variant="outlined"
                sx={{
                  '& input': {
                    padding: '5px',
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box> */}
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              {/* <Badge badgeContent={4} className='badge'  sx={{ marginRight: '20px' }}>
              <Tooltip title="Notification" placement="bottom" arrow>
                <CircleNotificationsOutlinedIcon cursor={'pointer'}/>
              </Tooltip>
              </Badge> */}
               <Tooltip title="Logout" arrow style={{ backgroundColor: 'white' }}>
               <IconButton color="black" onClick={handleLogout}>
        <PowerSettingsNewIcon />
      </IconButton>
      </Tooltip>
              <Box sx={{ display: 'flex', padding: '10px', alignItems: 'flex-start' }}>
  <Box sx={{ marginRight: '20px' }}>
    
    <Typography color='#fff'>
      {parseData && parseData.user.companyData.companyName}
    </Typography>
    <Typography color='#fff'>
      {parseData && parseData.user.name}
    </Typography> 
    <Typography color='#fff'>
      {parseData && parseData.user.rolesData.roleName}
    </Typography>
   
  </Box> 

  <div style={{
  overflow: 'hidden',
  width: '125px',
  // height: '60px',
  backgroundColor: 'white',
  margin: 'auto',  // Center the container
  
  // Responsive styles using media queries
  '@media (maxWidth: 767px)': {
    width: '75px',  // Adjust width for smaller screens
    // height: '40px',  // You can uncomment and adjust height if needed
  },
}}>
  <img
    src={parseData?.user.companyData.companyLogo}
    alt="Company Logo"
    style={{
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      // display: 'block',
    }}
  />
</div>

</Box>



          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <BusinessCenterIcon
            color='#1976D2'
            marginright={3}
            sx={{ fontSize: 28, }}
            style={{ marginRight: '6px', marginBottom: '5px',color :'#1976D2'}}
          />
          <Typography 
            variant="h6"
            
            component="div"
            color='primary'
            paddingRight={1}
            sx={{ fontSize: 20 }}
            style={{ fontWeight: '700' }}
          >
            Hiring 
          </Typography>
          <Typography
            variant="h6"
           
            component="div"
            color='#313D4F'
            paddingRight={1}
            sx={{ fontSize: 20}}
            style={{ fontWeight: '700', }}
          >
            Insights
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>

        <Divider />
        {/* <List>
          {filteredSideMenuList.map((menu) => (
            <ListItem disablePadding key={menu.id}>
              <ListItemButton
                component={Link}
                to={menu.link}
                active={open && location.pathname === menu.link ? true : false}
                sx={{
                  backgroundColor: open && location.pathname === menu.link ? '#1976d2' : 'transparent',
                  color: open && location.pathname === menu.link ? '#fff' : 'inherit', 
                  '&:hover': {
                    backgroundColor: '#1976d2',
                    color: '#fff' 
                  },
                }}
              >
                {open ? (
                  <ListItemIcon sx={{ color: open && location.pathname === menu.link ? '#fff' : 'inherit' }}>
                    {React.cloneElement(menu.icon, { style: { fontSize: 24 } })} 
                  </ListItemIcon>
                ) : (
                  <Tooltip title={menu.displayName} placement="right" arrow>
                    <ListItemIcon sx={{ color: open && location.pathname === menu.link ? '#fff' : 'inherit' }}>
                      {menu.icon && React.cloneElement(menu.icon, { style: { fontSize: 24 } })}
                    </ListItemIcon>
                  </Tooltip>
                )}
                <ListItemText primary={menu.displayName} />
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}
        <List>
      <SideMenu open={open}  handleProfileMatchingClick={handleProfileMatchingClick}/>
    </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <AppRoutes />
      </Box>
    </Box>
    </ThemeProvider>

  );
}
