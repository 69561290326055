import { Fragment } from 'react'

import HistoryReportBreadcrumbs from '../../components/Breadcrumbs'
import BlockedCompaniesIn from './blockedCompanies'

const BlockedCompanies = () => {
    return (
        <Fragment>
            <HistoryReportBreadcrumbs title='Blocked Companies' data={[{ link: '/campaign-dashboard', title: 'Campaign Dashboard' }]}/>
            <div>
                <BlockedCompaniesIn />
            </div>
        </Fragment>
    )
}

export default BlockedCompanies;
