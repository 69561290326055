import { Button, Card, CardContent, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { DataGrid } from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import GlobalVariable from '../../path/global';
import { toast, Toaster } from 'react-hot-toast';

import CircularProgressWithLabel from '../../components/reusableComponents/CircularProgressWithLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ReusableEditor from "../../components/reusableComponents/Editor";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
const baseURI = GlobalVariable.BASE_API_GOOGLE_URL;


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Jobtemplate = () => {
  const [open, setOpen] = useState(false);
  const [buttonLabel, setButtonLabel] = useState('Add Template'); 
  const [rows, setRows] = useState([]); 
  const [disableButton, setDisableButton] = useState(false);
  const [buttonClass, setButtonClass] = useState('custom-button');
  const [progress, setProgress] = useState(0);
  const [isCircleLoading, setCircleIsLoading] = useState(false);
  const [templateName, setTemplateName] = useState(null);
  const [selectedURLToDelete, setSelectedURLToDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [tempId, setTempId] = useState(null)
  const [text, setText] = useState(null);
  const [modelName, setModelName] = useState("Add");
  const [subject, setSubject] = useState(null)
  const handleClickOpen = () => {
    setOpen(true);
    setSelectedURLToDelete(false);
    setDeleteId(null);
    setButtonLabel("Add Template")
    setModelName("Add")
    setTemplateName("");
    setSubject('')
    setText("");
  };

  const isFieldDisabled = modelName === "View";

  const handleEdit = (row, mode) => {
    setTemplateName(row.name || "");
    setSubject(row.subject || "");
    setText(row.template || "");
   
    setTempId(row.jetemplateid);
    if(mode === "Edit"){
        setButtonLabel("Edit Template")
        setModelName(mode);
    }
    setOpen(true);
  }
 

  const handleDelete = (row) => {
    setSelectedURLToDelete(true);
    setDeleteId(row.jetemplateid);
    setOpen(true);
    setModelName("Delete")
  }

  console.log(modelName)

  const userDetails = JSON.parse(localStorage.getItem("userdetails"));
  const username = userDetails.user.name
  const userid = userDetails.user.id

  console.log(username, userid)
  const handleClose = () => {
    setOpen(false);
    setSelectedURLToDelete(false);
    setDeleteId(null);
};

  const fetchMailTemp = async () => {
    setProgress(10);
    setCircleIsLoading(true)
    try{
        setProgress(30);
      const response = await fetch(`${baseURI}job_analysis/businesstool/viewalljetemp`, {
        method: 'GET'
      }); 

      setProgress(40);

      if (response.ok) {
        const data = await response.json(); 

        setRows(data.details)
        setProgress(100);
        setCircleIsLoading(false)
        setDisableButton(false)
      } else {
        console.error(`Error: ${response.status} - ${response.statusText}`);
      }
    }catch(error){
      console.error('fetching the error users',error);
    }
    
  }
  useEffect(() => {
    fetchMailTemp()
  },[])


  

  const sendDataToBackend = async () => {

    if (selectedURLToDelete && modelName === "Delete") {
        console.log("Delete Id:", deleteId)
        setProgress(10)
        setCircleIsLoading(true)
        try {
          const response = await fetch(`${baseURI}job_analysis/businesstool/deljetemp`,{
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ jetemplateid: deleteId }),
          });
          if (response.ok) {
            toast.success('Record deleted successfully.');
            handleClose();
            fetchMailTemp(); 
            setProgress(100)
            setCircleIsLoading(false)
          } else {
            toast.error('Failed to delete the record.');
          }
        } catch (error) {
          toast.error('An error occurred while deleting the record.');
        }
      }else if(modelName === "Edit"){

        try {
            setProgress(30);
            const response = await fetch(`${baseURI}job_analysis/businesstool/editjetemp`, {
                method: 'PUT',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name: templateName, template: text, jetemplateid: tempId , status: 0  }), 
            });
            setProgress(40);
            
    
            const responseData = await response.json();
            setCircleIsLoading(false);
            if (!response.ok) {
                if(response.status === 500){
                toast.error(response.statusText);
                } else {
                throw new Error('Network response was not ok');
                }
            } else {
                setProgress(50);
                toast.success(responseData.message);
    
    
                fetchMailTemp()
    
                setTimeout(() => {
                setProgress(70);
                setTimeout(() => {
                    setProgress(100);
                    setTimeout(() => {          
                    setCircleIsLoading(false);
                    }, 300);
                }, 500);
                },800);
    
            }
    
            handleClose();
            } catch (error) {
            console.log(error);
            toast.error(`Error: ${error.message}`);
            }
      }else{
        setProgress(10);
        setCircleIsLoading(true)


        try {
        setProgress(30);
        const response = await fetch(`${baseURI}job_analysis/businesstool/addjetemp`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name: templateName, template: text, status: 1  }), 
        });
        setProgress(40);
        
        const responseData = await response.json();
        console.log(response)
        setCircleIsLoading(false);
        if (!response.ok) {
            if(response.status === 500){
            toast.error(response.statusText);
            } else {
            throw new Error('Network response was not ok');
            }
        } else {
            setProgress(50);
            toast.success(responseData.message);


            fetchMailTemp()

            setTimeout(() => {
            setProgress(70);
            setTimeout(() => {
                setProgress(100);
                setTimeout(() => {          
                setCircleIsLoading(false);
                }, 300);
            }, 500);
            },800);

        }

        handleClose();
        } catch (error) {
        console.log(error);
        toast.error(`Error: ${error.message}`);
        }
}
  };

  const NoRowsOverlay = () => (
    <Box sx={{ p: 2, textAlign: 'center', color: 'gray' }}>No records</Box>
  );


  // Data Columns
  const columns = [
    { field: 'name', headerName: 'Template Name', flex: 1 },
    // { field: 'subject', headerName: 'Subject', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 2,
      renderCell: (params) => {
        const handleSetDefault = async (row) => {
          const templateName = row.name || "Test1";
          const text = row.template || "Test3";
          const response = await fetch(`${baseURI}job_analysis/businesstool/editjetemp`, {
            method: 'PUT',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name: templateName, template: text, jetemplateid: row.jetemplateid , status: 1  }), 
          });
          if (response.ok) {
            const responseData = await response.json();
            console.log(responseData)
            fetchMailTemp(); 
          }
        };
  
        return (
          <>
            <Tooltip title="View Template" arrow>
              <IconButton
                aria-label="view"
                onClick={() => handleEdit(params.row, "view")}
                className='button-a'
              >
                <RemoveRedEyeIcon color="primary" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit Template" arrow>
              <IconButton
                aria-label="edit"
                onClick={() => handleEdit(params.row, "Edit")}
                className='button-a'
              >
                <EditIcon color="primary" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Template" arrow>
              <IconButton
                aria-label="delete"
                onClick={() => handleDelete(params.row)}
                className='button-a'
              >
                <DeleteIcon color="primary" />
              </IconButton>
            </Tooltip>
            <Tooltip title={params.row.status === 1 ? "Default Template" : "Set as Default Template"} arrow>
              <IconButton
                aria-label="toggle-default"
                onClick={() => handleSetDefault(params.row)}
                className='button-a'
                disabled={params.row.status === 1}
              >
                {params.row.status === 1 ? (
                  <ToggleOnIcon color="primary" />
                ) : (
                  <ToggleOffIcon color="action" />
                )}
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];
  

  const buttonStyle = {
    width: `${buttonLabel.length * 15}px`, 
  };

  return (
    <>
      <div className='job-extractor'>
      <Toaster position="top-right" reverseOrder={false} />
        <Card variant="outlined" sx={{ borderRadius: '10px' }}>
        {isCircleLoading ? (
        <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '70vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              zIndex: 10,
            }}
          >
            <CircularProgressWithLabel value={progress} />
            <Typography variant="body1" sx={{ marginTop: 2, color: 'text.secondary' }}>
              Loading...
            </Typography>
          </Box>
        ) : (
          <div className='jobextractor-table'>
            <Box>
              <CardContent style={{ paddingBottom: '10px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box className="JobExtractorView" alignItems="center" justifyContent="space-between">
                      <Typography variant='h5' sx={{ fontWeight: '600' }}></Typography>
                      <Box alignItems="center" className="dd" style={{display: 'flex'}}>

                        <TextField label="Search" variant="outlined" size="small" style={{ marginRight: '8px' }} />
                        
                        <Tooltip title="Add Template" arrow>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: '8px' }}
                            className='button-r'
                            onClick={handleClickOpen}
                          >
                            Add Job Template
                          </Button>
                        </Tooltip>
                      </Box>
                    
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    getRowId={(row) => row.jetemplateid}
                    className="job-extractor-table"
                    slots={{ noRowsOverlay: NoRowsOverlay }}
                    slotProps={{
                      noRowsOverlay: {
                        sx: { fontSize: '16px', fontWeight: 'bold', color: 'gray' },
                      },
                    }}
                    autoHeight
                  />
                  </Grid>
                </Grid>
              </CardContent>

              <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={(event, reason) => {
                  if (reason !== "backdropClick") handleClose();
                }}
                aria-describedby="alert-dialog-slide-description"
                fullWidth
                maxWidth={modelName === "Delete" ? "sm":"md"}
                className="job-extractor"
                disableEscapeKeyDown
              >
                <DialogTitle >
                <Typography variant='h6' className='dialog-title bottom-line'> 
                {modelName === "Add" ? "Add Job Template" : modelName === "Edit" ? "Edit Job Template" : modelName === "view" ? "View Job Template" 
                   
                    : "Delete Confirmation"}
                </Typography>
                 
                  <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                    className='bg-primary'
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                {modelName === "Add" && (
                  <Grid container spacing={2} className='placeholder'>
                  <Grid item xs={12} sm={10} >
                    <Typography variant='h6' style={{paddingLeft: '5px', paddingBottom: '20px'}}><span style={{fontWeight:'600'}}>Notation -</span> <span style={{fontWeight:'400'}}>“Please use symbol '&#x7b;&#x7d;' inplace where the company website is to be entered”</span> </Typography>                    
                  </Grid>
                </Grid>
                )}
                {modelName === "Edit" && (
                  <Grid container spacing={2} className='placeholder'>
                  <Grid item xs={12} sm={10} >
                    <Typography variant='h6' style={{paddingLeft: '5px', paddingBottom: '20px'}}><span style={{fontWeight:'600'}}>Notation - </span><span style={{fontWeight:'400'}}>“Please use symbol '&#x7b;&#x7d;' inplace where the company website is to be entered”</span></Typography>                     
                  </Grid>
                </Grid>
                )}
                {selectedURLToDelete ? (
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you want to delete this record?
                        <Box sx={{ marginTop: '20px', textAlign: 'right' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={sendDataToBackend}
                            sx={{ marginRight: '8px' }}
                        >
                            Confirm
                        </Button>
                        <Button variant="contained" color='error' onClick={handleClose}>
                            Cancel
                        </Button>
                        </Box>
                    </DialogContentText>
                ) : (
                    <DialogContentText id="alert-dialog-slide-description">
                    <Box id="drop-area">
                      {modelName === "view" ? (
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <Typography variant="body1" sx={{ marginBottom: '15px', paddingLeft: '0 !important' }}>
                            <strong>Template Name: </strong>
                              {templateName}
                            </Typography>
                          </Grid>
                          {/* <Grid item xs={12} sm={6}>
                            <Typography variant="body1" sx={{ marginBottom: '15px', paddingLeft: '0 !important' }}>
                            <strong>Subject: </strong>
                              {subject}
                            </Typography>
                          </Grid> */}
                        </Grid>
                        
                      ) : (
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6} style={{paddingBottom: '5px'}}>
                              <TextField
                              type="text"
                              value={templateName || ""}
                              onChange={(e) => setTemplateName(e.target.value)}
                              label="Template Name"
                              variant="outlined"
                              fullWidth
                              disabled={isFieldDisabled}
                            />
                          </Grid>
                          {/* <Grid item xs={12} sm={6} style={{paddingBottom: '5px'}}>
                              <TextField
                              type="text"
                              value={subject || ""}
                              onChange={(e) => setSubject(e.target.value)}
                              label="Subject"
                              variant="outlined"
                              fullWidth
                              disabled={isFieldDisabled}
                            />
                          </Grid> */}
                        </Grid>
                        
                      )}
                    </Box>
                    <Box style={{ paddingTop: '15px' }}>
                      {modelName === "view" ? (
                        <div
                          style={{
                            border: "1px solid #ccc",
                            padding: "10px",
                            borderRadius: "4px",
                            backgroundColor: "#f9f9f9",
                            maxHeight: "320px",
                            overflowY: "auto",
                          }}
                          dangerouslySetInnerHTML={{ __html: text }}
                        ></div>
                      ) : (
                        <ReusableEditor
                          value={text}
                          onChange={(newValue) => setText(newValue)}
                          height="320px"
                        />
                      )}
                    </Box>
                    {modelName !== "view" && (
                      <DialogActions>
                        <Button
                          onClick={sendDataToBackend}
                          className={buttonClass}
                          type="submit"
                          disabled={disableButton}
                          style={{
                            ...buttonStyle,
                            backgroundColor: disableButton ? "#d3d3d3" : "#1976d2",
                            color: disableButton ? "#9e9e9e" : "#fff",
                          }}
                        >
                          {buttonLabel}
                        </Button>
                      </DialogActions>
                    )}
                  </DialogContentText>
    
               
                
                  )}
                </DialogContent>
              </Dialog>
            </Box>
          </div>
        )}
        </Card>
      </div>
    </>
  );
}

export default Jobtemplate;
