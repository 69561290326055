// ** User List Component
import * as React from 'react';

import { Fragment, useState, useEffect } from "react";
import { IconButton, InputAdornment, TextField, Input } from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useNavigate } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress';
import Card from "@mui/material/Card";
import { CardContent, FormControl } from "@mui/material";
import Typography from "@mui/material/Typography";
import ResBreadcrumbs from "../../components/Breadcrumbs";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import GlobalVariable from "../../path/global";
import axios from "axios";
import SelectDropDown from "../../components/reusableComponents/selectDropDown";
import Table from "@mui/material/Table";
import SnackbarComponent from "../../components/toastmessage";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";

import { Button } from "@mui/material";
import Snackbar from "@material-ui/core/Snackbar";
import Tooltip from "@material-ui/core/Tooltip";
import MySnackbarContent from "@material-ui/core/SnackbarContent";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CircularLoader from "../../components/Loader/circularloader";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { toast, Toaster } from 'react-hot-toast';

const baseApiUrl = GlobalVariable.BASE_API_URL;
const baseURI = GlobalVariable.BASE_API_GOOGLE_URL;


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddUserList = () => {



  const navigate = useNavigate();
  const [ferrors, setErrors] = useState({});
  const [userName, setUserName] = useState("");
  const [manageUserName, setManageUserName] = useState("");
  const [email, setEmail] = useState("");
  const [editRow, setEditRow] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showSeamlessPassword, setShowSeamlessPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [domainPassword, setDomainPassword] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [roles, setRoles] = useState([]);
  const [phone, setPhone] = useState("");
  const [ofcPosition, setofcPosition] = useState("");
  const [seamlessEmail, setSeamlessEmail] = useState("");
  const [seamlessPassword, setSeamlessPassword] = useState("");
  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [numValid, setNumValid] = useState(false);
  const [prptName, setPromptName] = useState("");
  const [prptDescription, setPromptDescription] = useState("");
  const [updateId, setUpdateId] = useState("");
  const [filterText, setFilterText] = useState("");
  const [nextSNO, setNextSNO] = useState(1);
  const [toastMessage, setToastMessage] = useState(false);
  const [canCheck, setCanCheck] = useState(0);
  const [canRun, setCanRun] = useState(0);
  const [isProgress, setProgress] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [isLoading, setIsLoading] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [createdUserId, setCreatedUserId] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [officePosition, setPosition] = useState('');
  const [Domain, setDomain] = useState('');
  const [seamlessPasswordVisible, setSeamlessPasswordVisible] = useState(false);
  const [DomainPasswordVisible, setDomainPasswordVisible] = useState(false);
  const [emailDomains, setEmailDomains] = useState([]);
  const [openManageUser, setOpenManageUser] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors
  } = useForm({});

  const validateFields = () => {
    const newErrors = {};
  
    if (!phoneNumber || phoneNumber.trim() === '') {
      newErrors.phoneNumber = 'Phone Number is required';
    } else if (!/^\d{10}$/.test(phoneNumber)) {
      newErrors.phoneNumber = 'Phone Number must be 10 digits';
    }
  
    if (!officePosition || officePosition.trim() === '') {
      newErrors.officePosition = 'Office Position is required';
    }
  
    if (!seamlessEmail || seamlessEmail.trim() === '') {
      newErrors.seamlessEmail = 'Seamless Email is required';
    } else if (!/\S+@\S+\.\S+/.test(seamlessEmail)) {
      newErrors.seamlessEmail = 'Enter a valid email';
    }
  
    if (!seamlessPassword || seamlessPassword.trim() === '') {
      newErrors.seamlessPassword = 'Seamless Password is required';
    } else if (!/^[a-zA-Z0-9!@#$%^&*()_+=-]{6,20}$/.test(seamlessPassword)) {
      newErrors.seamlessPassword = 'Password must be at least 6 characters and can include letters, numbers, and symbols (!@#$%^&*()_+=-)';
    }
  
    if (!Domain || Domain.trim() === '') {
      newErrors.Domain = 'Domain is required';
    } else if (!/\S+@\S+\.\S+/.test(Domain)) {
      newErrors.Domain = 'Enter a valid email';
    }
  
    if (!domainPassword || domainPassword.trim() === '') {
      newErrors.domainPassword = 'Domain Password is required';
    } else if (!/^[a-zA-Z0-9!@#$%^&*()_+=-]{6,20}$/.test(domainPassword)) {
      newErrors.domainPassword = 'Password must be at least 6 characters and can include letters, numbers, and symbols (!@#$%^&*()_+=-)';
    }
  
    emailDomains.forEach((item, index) => {
      if (!item.domain || item.domain.trim() === '') {
        newErrors[`emailDomains.${index}.domain`] = `Domain is required for entry ${index + 1}`;
      } else if (!/\S+@\S+\.\S+/.test(item.domain)) {
        newErrors[`emailDomains.${index}.domain`] = 'Enter a valid email';
      }
  
      if (!item.password || item.password.trim() === '') {
        newErrors[`emailDomains.${index}.password`] = `Password is required for entry ${index + 1}`;
      } else if (!/^[a-zA-Z0-9!@#$%^&*()_+=-]{6,20}$/.test(item.password)) {
        newErrors[`emailDomains.${index}.password`] = 'Password must be at least 6 characters and can include letters, numbers, and symbols (!@#$%^&*()_+=-)';
      }
    });
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  

  const addemailDomains = () => {
    const newEmailDomain = {
      domainid: "",
      domain: "",
      password: "",
      showPassword: false,
    };
    setEmailDomains([...emailDomains, newEmailDomain]);  
  }
  const togglePasswordVisibility = (index) => {
    const updatedDomains = [...emailDomains];
    updatedDomains[index].showPassword = !updatedDomains[index].showPassword;
    setEmailDomains(updatedDomains);
  };

  const updateEmailDomain = (index, key, value) => {
    const updatedDomains = [...emailDomains];
    updatedDomains[index][key] = value;
    setEmailDomains(updatedDomains);
  }

  const handleClicksShowPassword = () => {
    setSeamlessPasswordVisible(!seamlessPasswordVisible);
  };

  const handleClickDShowPassword=() => {
    setDomainPasswordVisible(!DomainPasswordVisible);
  }
  const deleteEmailDomain = (index) => {
    setEmailDomains(emailDomains.filter((_, i) => i !== index));
  };


  useEffect(() => {
    // Fetch data from your API endpoint
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseApiUrl}getRoles`);
        const rolesData = response.data.activeData;
        const rolesDat = rolesData.map((item) => ({
          value: item.id,
          label: item.roleName,
        }));
        setRoles(rolesDat); // Assuming the result is an array of objects with id and name fields
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    setEmail("");
    setUserName("");
    setPassword("");
    setPhone("");
    setSeamlessEmail("");
    setSeamlessPassword("");
    setofcPosition("");
    setSelectedRole("")
    fetchData();
  }, []);

  const userDetails = JSON.parse(localStorage.getItem("userdetails"));

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = () => {
    if (filterText.trim() === "") {
      // getData();
    } else {
      const escapedFilterText = filterText.replace(
        /[-\/\\^$*+?.()|[\]{}]/g,
        "\\$&"
      );
      const filterRegex = new RegExp(escapedFilterText, "i");

      const filteredItems = items.filter((item) => {
        const nameMatches =
          item.languageName && filterRegex.test(item.languageName);
        const daysMatches =
          item.languageDays &&
          (filterRegex.test(item.languageDays) ||
            item.languageDays.toString().includes(filterText));
        return nameMatches || daysMatches;
      });

      setItems(filteredItems);
    }
  };
  const totalPages = Math.ceil(10 / rowsPerPage);

  const handleSearchClear = () => {
    // getData();
    setFilterText("");
  };

  const handleEdit = (rowId) => {
    const selectedItem = items.find((item) => item.id === rowId);

    setEditRow(rowId);
    setUpdateId(rowId);
    setPromptName(selectedItem.promptName);
    setPromptDescription(selectedItem.promptDescription);
  };
  const handleFilterChange = (event) => {
    setFilterText(event.target.value);
  };
  
  const handleNameChange = (e) => {
    const { value } = e.target;
    setUserName(value);
    setValue('userName', e.target.value);
    clearErrors('userName');

  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEmail(value);
    setValue('email', e.target.value);
    clearErrors('email');
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setPassword(value);
    setValue('password', e.target.value);
    clearErrors('password');
  };
  
  const handlePhoneChange = (e) => {
    const { value } = e.target;
    setPhone(value);
    setValue('phone', e.target.value);
    clearErrors('phone');
  };

  const handlePositionChange = (e) => {
    const { value } = e.target;
    setofcPosition(value);
    setValue('ofcPosition', e.target.value);
    clearErrors('ofcPosition');
  };
  
  const handleSeamlessEmailChange = (e) => {
    const { value } = e.target;
    setSeamlessEmail(value);
    setValue('seamlessEmail', e.target.value);
    clearErrors('seamlessEmail');
  };

  const handleSeamlessPasswordChange = (e) => {
    const { value } = e.target;
    setSeamlessPassword(value);
    setValue('seamlessPassword', e.target.value);
    clearErrors('seamlessPassword');
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
   
  };
  const handleClickShowSeamlessPassword = () => {
    setShowSeamlessPassword(!showSeamlessPassword)
   
  };
  

  const handleChange = async (event) => {
    setSelectedRole(event.value);
    setIsLoading(true);
    let n = event.value;

    try {
      const promptData = await axios.get(`${baseApiUrl}getRolePrompts/${n}`);


      setItems(promptData.data.activeData);
      setIsLoading(false);
      // setPromptRows(prompts)
    } catch (error) {
      console.error("Error fetching prompt data:", error);
    }
  };

    const handleClickOpen = (userId, userName) => {
      setCreatedUserId(userId);
      setOpenConfirmation(true);
      setManageUserName(userName)
    };
  const handleClose = () => {
    setOpenConfirmation(false)
    setOpenManageUser(false)
    setErrors({});
  };



  const handleConfirm = () => {
    setOpenConfirmation(false);
    setOpenManageUser(true);
    setErrors({});
  }
  const onSubmit = async () => {
    setIsSubmitDisabled(true);
    setIsLoading(true);
    const editedPrompts = items.map((row) => ({
      promptName: row.promptName,
      promptDescription: row.promptDescription,
    }));
    const companyId = userDetails.user.companyId;

    const userPayload = {
      name: userName,
      email: email,
      password: password,
      roleId: selectedRole,
      companyId: companyId,
      prompts: editedPrompts,
      changePrompt: canCheck,
      phone: phone,
      ofc_position: ofcPosition,
      seamless_email: seamlessEmail,
      seamless_password: seamlessPassword,
      run_campaign: canRun,
      // user_permission: canUserPermission
    };

    await axios
      .post(`${baseApiUrl}createuser`, userPayload)
      .then((response) => {
        if (response.data.status === false) {
          setSnackbarMessage(response.data.message);
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
          setIsLoading(false);
          
        } else if (response.status === 200) {
          setSnackbarMessage("User Added Successfully");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
          const userId = response.data.activeData.id;
          const userName = response.data.activeData.name
          const roleId = response.data.activeData.roleId

          if(roleId === 5 || roleId === 2){
            handleClickOpen(userId, userName);
          }else{
           navigate('/users');
          }
        
         
          setIsLoading(false);
          
        }
      })
      .catch((error) => {
        if (error.response && error.response.data.status_code === 401) {
          setSnackbarMessage("User Authentication failed");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
          setIsLoading(true);
        } else if (error.response && error.response.data.status_code === 422) {
          setSnackbarMessage("Please check the your data object");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
          setIsLoading(true);
        } else {
          // Handle generic error
        }
      })
      .finally(() => {
        setIsSubmitDisabled(false); // Enable the submit button after login attempt
      });
    setEmail("");
    setUserName("");
    setPassword("");
    setSelectedRole("");
  };

  const handleUserSubmit = async () => {
   
    if (!validateFields()) {
      return;
    }
    setProgress(true)

    const updatedEmailDomains = [
      ...emailDomains.map(({ domain, password, domainid }) => ({
        domainid: domainid || "",
        domain: domain.trim(),
        password: password.trim(),
      })),
      ...(Domain && domainPassword
        ? [
            {
              domainid: "",
              domain: Domain.trim(),
              password: domainPassword.trim(),
            },
          ]
        : []),
    ];

    const seamlessUserData = {
      userid: createdUserId,
      manageuserid: "",
      phone_number: phoneNumber,
      office_position: officePosition,
      seamless_credentials: {
        email: seamlessEmail,
        password: seamlessPassword,
      },
      email_domains: updatedEmailDomains,
    };

    try {
      const response = await fetch(`${baseURI}job_analysis/businesstool/addnupdateuser`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(seamlessUserData),
      });

      console.log("Checking response:",response)
      if (response.ok) {

        setProgress(false)
        toast.success('Record added successfully.');
        handleClose();
        navigate('/users');

      } else {
        toast.error('Failed to add the record.');
      }
    } catch (error) {
      toast.error('An error occurred while adding the record.');
    }
  }

  const UpdateSave = (id) => {
    setNumValid(false);


    const reg = new RegExp("^[0-9]+$");
    const res = /^[a-zA-Z]+$/;

    if (prptName === "") {
      // Handle the case when prptName is empty
    } else if (prptDescription === "") {
      // Handle the case when prptDescription is empty
    } else if (reg.test(prptName) === true) {
      setOpen(true);
    } else if (res.test(prptDescription) === true) {
      setOpen(true);
      setNumValid(true);
    } else {
      const updatedItems = items.map((item) =>
        item.id === id
          ? {
            ...item,
            promptName: prptName,
            promptDescription: prptDescription,
          }
          : item
      );

      setItems(updatedItems);

      setPromptDescription("");
      setPromptName("");
      setEditRow("");
      setUpdateId("");
    }
  };

  const UpdateCancel = () => {
    setEditRow("");
    setPromptDescription("");
    setPromptName("");
  };

  const handleCheck = (event) => {
    console.log(event.target.checked)
    setCanCheck(event.target.checked ? 1 : 0);
    setCanRun(event.target.checked ? 1 : 0);
    // setCanUserPermission(event.target.checked ? 3 : 0);
  };
  const filteredData = [];
  // items.filter((item) => {
  //   const nameMatches = item.languageName.toLowerCase().includes(filterText.toLowerCase());
  //   const daysMatches = item.languageDays.toLowerCase().includes(filterText.toLowerCase());
  //   return nameMatches || daysMatches;
  // });
  return (
    <Fragment style={{position: 'relative'}}>
     
    <Toaster position="top-right" reverseOrder={false} />

      <ResBreadcrumbs title="Users " data={[{ title: "Dashboard" }]} />

      <div className="app-user-list" style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            background: "rgba(255,255,255, 0.5)",
            zIndex: "1025",
          }}
        >
          {isLoading && <CircularLoader />}
        </div>

        <Card>
          <Box sx={{ padding: "10px 15px" }}>
            <Typography fontSize={20} fontWeight={700}>
              Add User
            </Typography>
          </Box>
          <CardContent>
            <Box
              component={"form"}
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              sx={{ marginBottom: "50px" }}
            >
              <Grid
                container
                spacing={2}
                alignItems={"center"}
                sx={{ padding: "0px 0px 16px " }}
              >
                <Grid item xs={12} sm={3} md={3}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    sm={12}
                    id="userName"
                    label="User Name"
                    name="userName"
                    {...register("userName", {
                      required: "User Name is required",
                    })}

                    error={Boolean(errors.userName)}
                    helperText={errors.userName?.message}
                    value={userName}
                    onChange={(e) => {

                      handleNameChange(e);
                    }}
                    autoComplete="Off"
                    autoFocus
                    sx={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    sm={12}
                    id="email"
                    label="Email"
                    name="email"
                    {...register("email", {
                      required: "Email is required",
                    })}
                    error={Boolean(errors.email)}
                    helperText={errors.email?.message}
                    value={email}
                    onChange={(e) => {
                      handleEmailChange(e);
                    }}
                    autoComplete="Off"
                    sx={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={password}
                    label="Password"
                    {...register("password", {
                      required: "Password is required",
                      minLength: {
                        value: 6,
                        message: "Password must be at least 6 characters",
                      },
                    })}
                    error={Boolean(errors.password)}
                    helperText={errors.password?.message}
                    onChange={(e) => {
                      handlePasswordChange(e);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    id="password"
                    autoComplete="current-password"
                    sx={{ width: "100%" }}
                    className="password-input"
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <FormControl fullWidth sx={{ top: "3px" }}>
                    <SelectDropDown
                      valueData={roles}
                      value={selectedRole}
                      onJobChange={handleChange}
                      placeholder="Select Role"
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      marginBottom: "50px",
                    }}
                  >
                    <Table>
                      <div>
                        <div></div>
                      </div>
                      <Table className="adduser_table">
                        <TableHead>
                          <TableRow className="internshiptable">
                            <TableCell>Prompt Name</TableCell>
                            <TableCell style={{ width: "70%" }}>
                              Prompt Description
                            </TableCell>
                            <TableCell>Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(filteredData.length === 0 ? items : filteredData)
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .filter(
                              (row) =>
                                !filterText.length ||
                                row.languageName
                                  .toString()
                                  .toLowerCase()
                                  .includes(filterText.toString().toLowerCase())
                            )
                            .map((item, index) => (
                              <TableRow key={item.id}>
                                <TableCell
                                  style={{ padding: "0px", paddingLeft: 15 }}
                                >
                                  {editRow === item.id ? (
                                    <TextField
                                      error={prptName === ""}
                                      id="outlined-error"
                                      helperText={
                                        prptName === ""
                                          ? "Please enter name."
                                          : ""
                                      }
                                      defaultValue={item.promptName}
                                      value={prptName}
                                      onChange={(e) =>
                                        setPromptName(e.target.value)
                                      }
                                      className="input"
                                    />
                                  ) : (
                                    item.promptName
                                  )}
                                </TableCell>
                                <TableCell
                                  style={{ padding: "0px", paddingLeft: 10 }}
                                >
                                  {editRow === item.id ? (
                                    <TextField
                                      error={prptDescription === ""}
                                      id="outlined-error"
                                      helperText={
                                        prptDescription === ""
                                          ? "Please enter days."
                                          : ""
                                      }
                                      defaultValue={item.promptDescription}
                                      value={prptDescription}
                                      onChange={(e) =>
                                        setPromptDescription(e.target.value)
                                      }
                                      className="input input-jobDes"
                                    />
                                  ) : (
                                    item.promptDescription
                                  )}
                                </TableCell>
                                <TableCell style={{ padding: "0px" }}>
                                  {editRow === item.id ? (
                                    <>
                                      <Tooltip title="Save">
                                        <IconButton
                                          onClick={() => UpdateSave(item.id)}
                                        >
                                          <CheckCircleIcon />
                                        </IconButton>
                                      </Tooltip>
                                      <IconButton onClick={UpdateCancel}>
                                        <CloseIcon />
                                      </IconButton>
                                    </>
                                  ) : (
                                    <>
                                      <IconButton
                                        onClick={() => handleEdit(item.id)}
                                      >
                                        <EditIcon />
                                      </IconButton>
                                      {/* <IconButton onClick={() => handleDelete(item.id)}>
                                        <DeleteIcon  style={{display:'none'}}/>
                                      </IconButton> */}
                                    </>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                      {items.length === 0 ? (
                        <Table>
                          <p
                            style={{
                              textAlign: "center",
                              width: "100%",
                              alignSelf: "center",
                            }}
                          >
                            No data found
                          </p>
                        </Table>
                      ) : null}
                    </Table>

                    {toastMessage && (
                      <Snackbar
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={toastMessage}
                        autoHideDuration={6000}
                      // onClose={handletoastCloseMessage}
                      >
                        <MySnackbarContent
                          // onClose={handletoastCloseMessage}
                          variant="elevation"
                          message={this.state.notificationMessage}
                        />
                      </Snackbar>
                    )}
                  </div>
                </Grid>
              </Grid>
              <Grid container justifyContent={"flex-end"} alignItems={"center"}>
              
                <div>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      onChange={handleCheck}
                      label=" Change Prompts?"
                    />
                  </FormGroup>
                </div>
                <div>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      onChange={handleCheck}
                      label=" Run Campaign?"
                    />
                  </FormGroup>
                </div>

                {/* {selectedRole === 1 && (
                  <div>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        onChange={handleCheck}
                        label="User Permissions?"
                      />
                    </FormGroup>
                  </div>
                )} */}
                <Button
                  color="primary"
                  type="submit"
                  disabled={isSubmitDisabled}
                  className="bg-primary"
                  sx={{ marginTop: "10px" }}
                >
                  Save User
                </Button>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </div>

      <SnackbarComponent
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

      <Dialog
        open={openConfirmation}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        className=''
        aria-describedby="alert-dialog-slide-description"
      >
        
        <DialogContent>
           <DialogTitle>
                  <Typography variant='h6' className='dialog-title bottom-line' style={{paddingLeft: '0px !important'}}>  {`Manage the seamless user`}</Typography>
                  <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                    className='bg-primary'
                  >
                    <CloseIcon />
                  </IconButton>
           </DialogTitle>
          <DialogContentText id="alert-dialog-slide-description" style={{paddingLeft: '0px !important'}}>
              Do you want to add the seamless user details?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}  variant="contained"
                    color="error">Cancel</Button>
          <Button onClick={handleConfirm}  variant="contained"
                    color="primary">Confirm</Button>
        </DialogActions>
      </Dialog>

      {/* Manage User Dialog */}
      <Dialog
                  open={openManageUser}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={(event, reason) => {
                    if (reason !== "backdropClick") handleClose();
                  }}
                  aria-describedby="alert-dialog-slide-description"
                  fullWidth
                  maxWidth={"md"}
                  className="manage-user"
                  disableEscapeKeyDown
                >
                   {isProgress && (
                    <Box sx={{ display: 'flex', backgroundColor: '#f3f3f38c', 
                    position: 'absolute', 
                    zIndex: '1405',
                    width: '100%',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center' }}>
                      <CircularProgress />
                    </Box>
                    )}
                  <DialogTitle>
                    <Typography variant='h6' className='dialog-title bottom-line'>   {"Add User"}
                    </Typography>
                    <IconButton
                      aria-label="close"
                      onClick={handleClose}
                      sx={{ position: 'absolute', right: 8, top: 8 }}
                      className='bg-primary'
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent
                    style={{ padding: '0px' }}
                  >
                    
                      <DialogContentText
                        id="alert-dialog-slide-description"
                        style={{ marginTop: '0px', padding: '0px' }}
                      >
                        <Box id="drop-area"
                          sx={{
                            padding: ' 10px 20px',
                            textAlign: 'center',
                            borderRadius: '5px',
                            marginBottom: '20px'
                          }}
                        >
                          <Box sx={{ marginBottom: '10px' }}>
                           
                            <fieldset className='fieldset'>
                              <legend className='legend'>User Information</legend>
                              <Grid
                                container
                                spacing={2}
                                alignItems={"center"}
                                style={{ padding: '0px' }}
                              >                          
                             
                                <Grid item xs={12} sm={4} md={4} style={{ paddingTop: '10px' }}>
                                    
                                          <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            type="text"
                                            label="User Name"
                                            placeholder="Username"
                                            value={manageUserName}
                                            onChange={(e) => setManageUserName(e.target.value)}
                                            disabled
                                            sx={{ width: "100%" }}
                                          />
                                      
                                      </Grid>
                                
                                <Grid item xs={12} sm={4} md={4} style={{ paddingTop: '10px' }}>
                                  <TextField
                                    size='small'
                                    margin="normal"
                                    fullWidth
                                    type="text"
                                    label="Phone Number"
                                    placeholder='PhoneNumber'
                                    value={phoneNumber}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      if (/^\d*$/.test(value)  && value.length <= 10) {
                                        setPhoneNumber(value);
                                      }
                                    }}
                                    sx={{ width: "100%" }}
                                    error={!!ferrors.phoneNumber}
                                    helperText={ferrors.phoneNumber}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} style={{ paddingTop: '10px' }}>
                                  <TextField
                                    size='small'
                                    margin="normal"
                                    fullWidth
                                    type="text"
                                    label="Office Position"
                                    placeholder='Office Position'
                                    value={officePosition}
                                    onChange={(e) => setPosition(e.target.value)}
                                    sx={{ width: "100%" }}
                                    error={!!ferrors.officePosition}
                                    helperText={ferrors.officePosition}
                                  />
                                </Grid>

                              </Grid>
                            </fieldset>
                            <Box
                              style={{ marginBottem: '15px' }}
                            >  <fieldset>
                              <legend>Seamless Credentials</legend>
                              
                                <Grid
                                  container
                                  spacing={2}
                                  alignItems={"center"}
                                  style={{ padding: '0px' }}
                                >
                                  <Grid item xs={12} sm={6} md={6} style={{ paddingTop: '10px' }}>
                                    <TextField
                                      size='small'
                                      margin="normal"
                                      fullWidth
                                      type="text"
                                      label="Seamless Email"
                                      placeholder='SeamlessEmail'
                                      value={seamlessEmail}
                                      onChange={(e) => setSeamlessEmail(e.target.value)}
                                      sx={{ width: "100%" }}
                                      error={!!ferrors.seamlessEmail}
                                    helperText={ferrors.seamlessEmail}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6} style={{ paddingTop: '10px' }}>
                                    <TextField
                                      size='small'
                                      margin="normal"
                                      fullWidth
                                      type={seamlessPasswordVisible ? "text" : "password"}                                    
                                      label="Seamless Password"
                                      placeholder='SeamlessPassword'
                                      value={seamlessPassword}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                       
                                          setSeamlessPassword(value) 
                                        
                                      }}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end" >
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={handleClicksShowPassword}
                                              edge="end"
                                              className='password'
                                              sx={{color: '#1976d2 !important'}}
                                            >
                                              {seamlessPasswordVisible ? <VisibilityOff style={{color: '#333'}}/> : <Visibility style={{color: '#333'}}/>}
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                      sx={{ width: "100%" }}
                                      error={!!ferrors.seamlessPassword}
                                      helperText={ferrors.seamlessPassword}
                                    />
                                  </Grid>
                                </Grid>
                              </fieldset>
                            </Box>

                            <Box>
                            <fieldset>
                            <legend>Email Domains</legend>
                              
                              <Grid
                                container
                                spacing={2}
                                alignItems={"center"}
                                style={{ padding: '0px' }}
                              >
                                <Grid item xs={12} sm={5} md={5} style={{ paddingTop: '10px' }}>
                                  <TextField
                                    size='small'
                                    margin="normal"
                                    fullWidth
                                    type="text"
                                    label="Domain"
                                    placeholder='Domain'
                                    value={Domain}
                                    onChange={(e) => setDomain(e.target.value)}
                                    sx={{ width: "100%" }}
                                    error={!!ferrors.Domain}
                                    helperText={ferrors.Domain}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={5} md={5} style={{ paddingTop: '10px' }}>
                                  <TextField
                                    size='small'
                                    margin="normal"
                                    fullWidth
                                    type={DomainPasswordVisible ? "text" : "password"}                                     
                                    label="Password"
                                    placeholder='Password'
                                    value={domainPassword}
                                    onChange={(e) => {
                                      const value = e.target.value
                                    
                                        setDomainPassword(value)
                                      
                                      }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end" >
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickDShowPassword}
                                            edge="end"
                                            className='password'
                                          >
                                            {DomainPasswordVisible ? <VisibilityOff style={{color: '#333'}}/> : <Visibility style={{color: '#333'}}/>}
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                    sx={{ width: "100%" }}
                                    error={!!ferrors.domainPassword}
                                    helperText={ferrors.domainPassword}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} style={{ paddingTop: '0px', paddingRight:'16px' }}>
                                  <IconButton
                                    size="small"
                                    onClick={addemailDomains}
                                    
                                    style={{backgroundColor: '#1976d2', width: '38px', height: '38px', borderRadius: '50px'}}
                                  >
                                    <AddIcon className='btn' style={{width: '0.8em', height: '0.8em'}}/>                                  
                                  </IconButton>
                                </Grid>
                                {emailDomains.map((item, index) => (
      <Grid container spacing={2} alignItems="center" key={index} style={{ marginBottom: "8px", paddingLeft: "15px", paddingTop: "10px" }}>
        <Grid item xs={12} sm={5} md={5}>
          <TextField
            size="small"
            fullWidth
            value={item.domain}
            onChange={(e) => updateEmailDomain(index, "domain", e.target.value)}
            label="Domain"
            error={!!ferrors[`emailDomains.${index}.domain`]}
            helperText={ferrors[`emailDomains.${index}.domain`]}
          />
        </Grid>
        <Grid item xs={12} sm={5} md={5}>
          <TextField
            size="small"
            fullWidth
            type={item.showPassword ? "text" : "password"}
            value={item.password}
            onChange={(e) => updateEmailDomain(index, "password", e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => togglePasswordVisibility(index)}
                    edge="end"
                    className="password"
                  >
                    {item.showPassword ? (
                      <VisibilityOff style={{ color: "#333" }} />
                    ) : (
                      <Visibility style={{ color: "#333" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label="Password"
            error={!!ferrors[`emailDomains.${index}.password`]}
            helperText={ferrors[`emailDomains.${index}.password`]}
          />
        </Grid>
        <Grid item xs={12} sm={2} md={2} style={{ paddingLeft: "0px" }}>
          <IconButton
            size="small"
            style={{ backgroundColor: "#1976d2", width: "38px", height: "38px", borderRadius: "50px" }}
            onClick={() => deleteEmailDomain(index)}
          >
            <DeleteIcon style={{ width: "0.8em", height: "0.8em" }} />
          </IconButton>
        </Grid>
      </Grid>
    ))}
                              </Grid>
                              
                              </fieldset>
                            </Box>
                          </Box>
                          <Box>
                          
                              <Button variant="contained" component="span" onClick={handleUserSubmit}> {"Submit User Details"}</Button>
                        
                          </Box>
                        </Box>
                      </DialogContentText>
                
                  </DialogContent>
                </Dialog>
    </Fragment>
  );
};

export default AddUserList;
