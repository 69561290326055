import { Fragment } from 'react'

import HistoryReportBreadcrumbs from '../../components/Breadcrumbs'
import CompanySizeIn from './companySize'

const CompanySize = () => {
    return (
        <Fragment>
            <HistoryReportBreadcrumbs title='Company Sizes' data={[{ link: '/campaign-dashboard', title: 'Campaign Dashboard' }]}/>
            <div>
                <CompanySizeIn />
            </div>
        </Fragment>
    )
}

export default CompanySize;
