import { Fragment } from 'react'

import HistoryReportBreadcrumbs from '../../components/Breadcrumbs'
import CompanyNames from './companyName'

const CompanyNameIn = () => {
    return (
        <Fragment>
            <HistoryReportBreadcrumbs title='Companies' data={[{ link: '/campaign-dashboard', title: 'Campaign Dashboard' }]}/>
            <div>
                <CompanyNames />
            </div>
        </Fragment>
    )
}

export default CompanyNameIn;
