import './App.css';
import React, {useEffect} from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import MainLayout from './Layout/MainLayout';
import LoginRoute from './routes/authRoutes/LoginRoute';
import NotFound from './pages/notfound/NotFound';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PrivateRoute from './PrivateRoute/PrivateRoute';
import { useNavigate } from 'react-router-dom';
import GlobalVariable from './path/global';
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';

const baseApiUrl = GlobalVariable.BASE_API_URL;

const theme = createTheme({
  typography: {
    fontFamily: ['Nunito Sans', 'sans-serif'].join(',')
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Nunito Sans, sans-serif',
          fontWeight: 700,
          // Remove or adjust textTransform property
          letterSpacing: '1px',
          color: '#ffffff !important',
          fontSize: '18px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: 'Nunito Sans, sans-serif !important',
          padding: '10px',
          letterSpacing: '0.58px',
        },
        head: {
          backgroundColor: '#1976d2',
          fontSize: '18px', 
          fontWeight: 600
        },
        body:{
          fontSize: '16px'
        }
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          fontFamily: 'Nunito Sans, sans-serif !important'
        }
      }
    }
  },
});

theme.typography.h3 = {
  fontSize: '1rem',
  '@media (min-width:600px)': {
    fontSize: '1rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1rem',
  },
};
theme.typography.h4 = {
  fontSize: '0.89rem',
  '@media (min-width:600px)': {
    fontSize: '1rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.1rem',
  },
};

function App() {
  const getUserDetails = JSON.parse(localStorage.getItem('userdetails'));
  const isAuthenticated = getUserDetails && getUserDetails.access_token;
  const navigate = useNavigate()

return (
    <ThemeProvider theme={theme}>
      <div className='app'>
        <Routes>
              <Route
                path='/'
                element={isAuthenticated ? (
                  <Navigate to='/profile-matching' replace />
                ) : (
                  <Routes>
                    <Route path='/' element={<LoginRoute />} />
                  </Routes>
                )}
              />

            <Route
              path='/*' 
              element={
                isAuthenticated ? 
                  <PrivateRoute>
                    <MainLayout />
                  </PrivateRoute> 
                  : 
                  <Navigate to='/' replace />
              }
              index={<Navigate to='/' replace />}
            />
            <Route path='*' element={<NotFound />} />

          </Routes>
        </div>
    </ThemeProvider>
  );
}

export default App;
