import { Fragment } from 'react'

import HistoryReportBreadcrumbs from '../../components/Breadcrumbs'
import Mailtemplate from './mailTemplate'

const MailTemplate = () => {
    return (
        <Fragment>
            <HistoryReportBreadcrumbs title='Mail Templates' data={[{ title: 'Campaign Dashboard' }]}/>
            <div>
                <Mailtemplate />
            </div>
        </Fragment>
    )
}

export default MailTemplate;
