import React from "react";
import { Editor } from "primereact/editor";

const ReusableEditor = ({ value, onChange, height = "320px", headerTemplate }) => {
    // const renderDefaultHeader = () => {
    //     return (
    //         <span className="ql-formats">
    //             <button className="ql-bold" aria-label="Bold"></button>
    //             <button className="ql-italic" aria-label="Italic"></button>
    //             <button className="ql-underline" aria-label="Underline"></button>
    //         </span>
    //     );
    // };

    // const header = headerTemplate || renderDefaultHeader();

    return (
        <div className="card">
            <Editor
                value={value}
                onTextChange={(e) => onChange(e.htmlValue)}
                // headerTemplate={header}
                style={{ height }}
            />
        </div>
    );
};

export default ReusableEditor;
