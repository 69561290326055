import { Fragment } from 'react'

import HistoryReportBreadcrumbs from '../../components/Breadcrumbs'
import Campaign from './campaign-dashboard'

const CampaignDashboard = () => {
    return (
        <Fragment>
            <HistoryReportBreadcrumbs title='Campaign Dashboard' data={[{ link:'/profile-matching', title: 'Profile Matching' }]}/>
            <div>
                <Campaign />
            </div>
        </Fragment>
    )
}

export default CampaignDashboard;
