import React, {Fragment} from 'react';
import ResBreadcrumbs from '../../components/Breadcrumbs'
import JobExtractor from './jobExtractor';


const JobExtractorIn = () => {
    return(<Fragment>
        <ResBreadcrumbs title='Job Extractor' data={[{ title: 'Dashboard' }]}/>
            <div className='app-job-extractor'>
                <JobExtractor/>
            </div>
              
        </Fragment>);
}

export default JobExtractorIn;
