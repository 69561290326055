import React, {useEffect} from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import AddUserList from '../../pages/users/AddUsers';
import EditUserList from '../../pages/users/EditUser';
import JobDescriptionIn from '../../pages/jobdescription/index';
import ProfileAnalysisReport from '../../pages/HistoryReport/index';
import NotAuth from '../../pages/notfound/NotAuth';
import PrivateRoute from '../../PrivateRoute/PrivateRoute';
import ProfileMatchList from '../../pages/profilematching-page/index'
import UserData from '../../pages/users'
import  Prompt from '../../pages/prompts/index'
import UserPromptList from '../../pages/prompts/user-prompts/index'
import GlobalVariable from '../../path/global';
import axios from 'axios';
import CampaignDashboard from '../../pages/CampaignDashboard/index';
import BlockedCompanies from '../../pages/blockedCompanies';
import BlockedIndustries from '../../pages/blockedIndustires';
import CompanyNameIn from '../../pages/companyNames';
import CompanySize from '../../pages/companySize';
import JobNames from '../../pages/jobNames';
import UnsubscribedEmail from '../../pages/unsubscribedEmail';
import ViewSentEmail from '../../pages/ViewSentEmail';
import JobExtractorIn from '../../pages/JobExtractor';
import EmailCampaignIn from '../../pages/EmailCampaign';
import ManageUser from '../../pages/ManageUser';
import MailTemplate from '../../pages/mailTemplate';
import JobTemplate from '../../pages/jobTemplate';


const baseApiUrl = GlobalVariable.BASE_API_URL;



const AppRoutes = () => {
  const userRole = localStorage.getItem('userdetails');
  const ParseData = JSON.parse(userRole);
  const roleid = ParseData.user?.roleId;
  const navigate = useNavigate();

  axios.interceptors.request.use(
    (config) => {
      // ** Get token from localStorage
      const userDetails = JSON.parse(localStorage.getItem('userdetails'));
      const token = userDetails?.access_token;
      // const Bearer = userDetails?.token_type;
  
      const Authorization_token = token;
  
      // ** If token is present add it to the request's Authorization Header
      if (Authorization_token) {
        config.headers.Authorization = Authorization_token;
      }
  
      return config;
    },
    (error) => Promise.reject(error)
  );
  
  const fetchData = async () => {
    try {
      const userDetails = JSON.parse(localStorage.getItem('userdetails'));
      if (!userDetails) {
        // Handle the case where there is no user details
        return;
      }
  
      const options = {
        method: 'GET',
        url: `${baseApiUrl}tokenExpiry`,
      };
  
      const response = await axios(options);
  
      // Check the status_code in the response
      const statusCode = response.status;
  
  
      if (statusCode === 401) {
        localStorage.removeItem('userdetails');
        navigate('/')
        // Do something, e.g., redirect to login page
      } else if (statusCode === 200) {
        // Handle success, if needed
        console.log('Token is valid');
      } else {
        // Handle other status codes, if needed
        console.error('Unexpected status code:', statusCode);
      }
    } catch (error) {
      console.error('Error:', error);

      // Handle errors, e.g., if the server returns a 401 status code
      if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
        // Token expired or invalid, handle accordingly
        localStorage.removeItem('userdetails');
        navigate('/');
        // Do something, e.g., redirect to login page
      } else {
        // Handle other Axios errors or network issues
        console.error('Unexpected Axios error:', error.message);
      }
    }
  };
  
  useEffect(() => {
    fetchData();
  });
  const renderComponents = () => {
    switch (roleid) {
      case 1:
        return (
          <>
           <Route
          path='/users'
          element={
            <PrivateRoute>
          <UserData />
          </PrivateRoute>}
        />
         <Route
          path='/users/add-user'
          element={
            <PrivateRoute>
          <AddUserList />
          </PrivateRoute>}
        />
        <Route
          path='/users/edit-user/:id'
          element={
            <PrivateRoute>
          <EditUserList />
          </PrivateRoute>}
        />        
        <Route
          path='/profile-matching'
          element={
            <PrivateRoute>
            <ProfileMatchList />
            </PrivateRoute>
}
        />
        <Route
          path='/analysis-report'
          element={
            <PrivateRoute>
          <ProfileAnalysisReport />
          </PrivateRoute>}
        />
        <Route
          path='/job-description'
          element={
          <PrivateRoute>
          <JobDescriptionIn/>
          </PrivateRoute>}
        />
        <Route
          path='/role-prompts'
          element={
            <PrivateRoute>
          <Prompt/>
          </PrivateRoute>}
        />
        <Route
          path='/prompts'
          element={
          <PrivateRoute>
          <UserPromptList/>
          </PrivateRoute>}
        />
        <Route
          path='/campaign-dashboard'
          element={
          <PrivateRoute>
            <CampaignDashboard />
          </PrivateRoute>}
        />

        <Route
          path='/campaign-dashboard/job-extractor'
          element={
          <PrivateRoute>
            <JobExtractorIn/>
          </PrivateRoute>}
        />

        <Route
          path='/campaign-dashboard/email-campaign'
          element={
          <PrivateRoute>
            <EmailCampaignIn/>
          </PrivateRoute>}
        />

        <Route
          path='/campaign-dashboard/blocked-companies'
          element={
          <PrivateRoute>
            <BlockedCompanies />
          </PrivateRoute>}
        />

        <Route
          path='/campaign-dashboard/blocked-industries'
          element={
          <PrivateRoute>
            <BlockedIndustries />
          </PrivateRoute>}
        />

<Route
          path='/campaign-dashboard/company-names'
          element={
          <PrivateRoute>
            <CompanyNameIn />
          </PrivateRoute>}
        />
<Route
          path='/campaign-dashboard/company-sizes'
          element={
          <PrivateRoute>
            <CompanySize />
          </PrivateRoute>}
        />

<Route
          path='/campaign-dashboard/job-names'
          element={
          <PrivateRoute>
            <JobNames />
          </PrivateRoute>}
        />

<Route
          path='/campaign-dashboard/unsubscribed-email-size'
          element={
          <PrivateRoute>
            <UnsubscribedEmail />
          </PrivateRoute>}
        />

<Route
          path='/campaign-dashboard/view-sent-email'
          element={
          <PrivateRoute>
            <ViewSentEmail />
          </PrivateRoute>}
        />
        
        <Route
          path='/campaign-dashboard/manage-user'
          element={
          <PrivateRoute>
            <ManageUser />
          </PrivateRoute>}
        />
        <Route
          path='/campaign-dashboard/mail-templates'
          element={
          <PrivateRoute>
            <MailTemplate />
          </PrivateRoute>}
        />
<Route
          path='/campaign-dashboard/job-templates'
          element={
          <PrivateRoute>
            <MailTemplate />
          </PrivateRoute>}
        />
        <Route path='*' element={<NotAuth />} />
          </>
        );
      case 2:
        return (
          <>
             <Route
          path='/job-description'
          element={
          <PrivateRoute>
          <JobDescriptionIn/>
          </PrivateRoute>}
        />
            <Route path="/profile-matching" element={<PrivateRoute> <ProfileMatchList /></PrivateRoute>} />
            
        <Route
          path='/prompts'
          element={
          <PrivateRoute>
          <UserPromptList/>
          </PrivateRoute>}
        />
         <Route
          path='/analysis-report'
          element={
            <PrivateRoute>
          <ProfileAnalysisReport />
          </PrivateRoute>}
        />
          <Route
          path='/campaign-dashboard'
          element={
          <PrivateRoute>
            <CampaignDashboard />
          </PrivateRoute>}
        />

        <Route
          path='/campaign-dashboard/job-extractor'
          element={
          <PrivateRoute>
            <JobExtractorIn/>
          </PrivateRoute>}
        />

        <Route
          path='/campaign-dashboard/email-campaign'
          element={
          <PrivateRoute>
            <EmailCampaignIn/>
          </PrivateRoute>}
        />

        <Route
          path='/campaign-dashboard/blocked-companies'
          element={
          <PrivateRoute>
            <BlockedCompanies />
          </PrivateRoute>}
        />

         <Route path='*' element={<NotAuth />} />
          </>
        );
      case 3:
        return (
          <>
          
           <Route path="/profile-matching" element={<PrivateRoute><ProfileMatchList /></PrivateRoute>} />
          
        <Route
          path='/prompts'
          element={
          <PrivateRoute>
          <UserPromptList/>
          </PrivateRoute>}
        /> 
        <Route
        path='/analysis-report'
        element={
          <PrivateRoute>
            <ProfileAnalysisReport />
        </PrivateRoute>}
      />
      <Route
          path='/job-description'
          element={
          <PrivateRoute>
          <JobDescriptionIn/>
          </PrivateRoute>}
        />
        
       <Route path='*' element={<NotAuth />} />
          </>
        );
        case 4:
          return (
            <>
             <Route
            path='/users'
            element={
              <PrivateRoute>
            <UserData />
            </PrivateRoute>}
          />
           <Route
            path='/users/add-user'
            element={
              <PrivateRoute>
            <AddUserList />
            </PrivateRoute>}
          />
          <Route
            path='/users/edit-user/:id'
            element={
              <PrivateRoute>
            <EditUserList />
            </PrivateRoute>}
          />        
          <Route
            path='/profile-matching'
            element={
              <PrivateRoute>
              <ProfileMatchList />
              </PrivateRoute>
  }
          />
          <Route
            path='/analysis-report'
            element={
              <PrivateRoute>
            <ProfileAnalysisReport />
            </PrivateRoute>}
          />
          <Route
            path='/job-description'
            element={
            <PrivateRoute>
            <JobDescriptionIn/>
            </PrivateRoute>}
          />
          <Route
            path='/role-prompts'
            element={
              <PrivateRoute>
            <Prompt/>
            </PrivateRoute>}
          />
          <Route
            path='/prompts'
            element={
            <PrivateRoute>
            <UserPromptList/>
            </PrivateRoute>}
          />
          <Route
          path='/campaign-dashboard'
          element={
          <PrivateRoute>
            <CampaignDashboard />
          </PrivateRoute>}
        />

        <Route
          path='/campaign-dashboard/job-extractor'
          element={
          <PrivateRoute>
            <JobExtractorIn/>
          </PrivateRoute>}
        />

        <Route
          path='/campaign-dashboard/email-campaign'
          element={
          <PrivateRoute>
            <EmailCampaignIn/>
          </PrivateRoute>}
        />

        <Route
          path='/campaign-dashboard/blocked-companies'
          element={
          <PrivateRoute>
            <BlockedCompanies />
          </PrivateRoute>}
        />
         <Route
          path='/campaign-dashboard/blocked-industries'
          element={
          <PrivateRoute>
            <BlockedIndustries />
          </PrivateRoute>}
        />
         <Route
          path='/campaign-dashboard/company-names'
          element={
          <PrivateRoute>
            <CompanyNameIn />
          </PrivateRoute>}
        />
         <Route
          path='/campaign-dashboard/company-sizes'
          element={
          <PrivateRoute>
            <CompanySize />
          </PrivateRoute>}
        />
        <Route
          path='/campaign-dashboard/job-names'
          element={
          <PrivateRoute>
            <JobNames />
          </PrivateRoute>}
        />
        <Route
          path='/campaign-dashboard/manage-user'
          element={
          <PrivateRoute>
            <ManageUser />
          </PrivateRoute>}
        />
        <Route
          path='/campaign-dashboard/mail-templates'
          element={
          <PrivateRoute>
            <MailTemplate />
          </PrivateRoute>}
        />

<Route
          path='/campaign-dashboard/unsubscribed-email-size'
          element={
          <PrivateRoute>
            <UnsubscribedEmail />
          </PrivateRoute>}
        />

<Route
          path='/campaign-dashboard/view-sent-email'
          element={
          <PrivateRoute>
            <ViewSentEmail />
          </PrivateRoute>}
        />
        <Route
          path='/campaign-dashboard/job-templates'
          element={
          <PrivateRoute>
            <JobTemplate />
          </PrivateRoute>}
        />
        
          <Route path='*' element={<NotAuth />} />
            </>
          );
        case 5:
          return (
            <>
               <Route
            path='/job-description'
            element={
            <PrivateRoute>
            <JobDescriptionIn/>
            </PrivateRoute>}
          />
              <Route path="/profile-matching" element={<PrivateRoute> <ProfileMatchList /></PrivateRoute>} />
              
          <Route
            path='/prompts'
            element={
            <PrivateRoute>
            <UserPromptList/>
            </PrivateRoute>}
          />
           <Route
            path='/analysis-report'
            element={
              <PrivateRoute>
            <ProfileAnalysisReport />
            </PrivateRoute>}
          />
          <Route
          path='/campaign-dashboard'
          element={
          <PrivateRoute>
            <CampaignDashboard />
          </PrivateRoute>}
        />

        <Route
          path='/campaign-dashboard/job-extractor'
          element={
          <PrivateRoute>
            <JobExtractorIn/>
          </PrivateRoute>}
        />

        <Route
          path='/campaign-dashboard/email-campaign'
          element={
          <PrivateRoute>
            <EmailCampaignIn/>
          </PrivateRoute>}
        />
        
        <Route
          path='/campaign-dashboard/blocked-companies'
          element={
          <PrivateRoute>
            <BlockedCompanies />
          </PrivateRoute>}
        />

           <Route path='*' element={<NotAuth />} />
            </>
          );
        case 6:
          return (
            <>
            
             <Route path="/profile-matching" element={<PrivateRoute><ProfileMatchList /></PrivateRoute>} />
            
          <Route
            path='/prompts'
            element={
            <PrivateRoute>
            <UserPromptList/>
            </PrivateRoute>}
          /> 
          <Route
          path='/analysis-report'
          element={
            <PrivateRoute>
              <ProfileAnalysisReport />
          </PrivateRoute>}
        />
        <Route
            path='/job-description'
            element={
            <PrivateRoute>
            <JobDescriptionIn/>
            </PrivateRoute>}
          />
         
         <Route path='*' element={<NotAuth />} />
            </>
          );
      default:
        return <Navigate to="/login" replace />;
    }
  };

  return (
    <>
      <Routes>
        {renderComponents()}
      </Routes>
    </>
  );
};

export default AppRoutes;
