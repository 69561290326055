import React, { useState } from 'react';
import { PeopleAltOutlined, ContentPasteSearchOutlined, NoteAddOutlined, InfoOutlined, AnalyticsOutlined, ExpandLess, ExpandMore, FileDownload, WorkOutline, CampaignOutlined, WidgetsRounded, Dashboard } from '@mui/icons-material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Tooltip from '@mui/material/Tooltip';
import { Link, useLocation } from 'react-router-dom';

const getFilteredMenu = (roleId) => {
  const SideMenuList = [
    { id: 1, name: 'users', displayName: 'Users', link: '/users', icon: <PeopleAltOutlined /> },
    { id: 2, name: 'profile-matching', displayName: 'Profile Matching', link: '/profile-matching', icon: <ContentPasteSearchOutlined /> },
    { id: 3, name: 'jobde-description', displayName: 'Job Description', link: '/job-description', icon: <NoteAddOutlined /> },
    { id: 4, name: 'prompts', displayName: 'Prompts', link: '/prompts', icon: <InfoOutlined /> },
    { id: 5, name: 'roleprompts', displayName: 'Role Prompts', link: '/role-prompts', icon: <InfoOutlined /> },
    { id: 6, name: 'previous-analysis-report', displayName: 'Analysis Report', link: '/analysis-report', icon: <AnalyticsOutlined /> },

    // { id: 7, name: 'business-tool', displayName: 'Business Tool', link: '', icon: <WorkOutline />, children: [
    //     { id: 7.1, name: 'job-extractor', displayName: 'Job Extractor', link: '/business-tool/job-extractor', icon: <FileDownload /> },
    //     { id: 7.2, name: 'email-campaign', displayName: 'Email Campaign', link: '/business-tool/email-campaign', icon: <CampaignOutlined /> }
    //   ]
    // },
    { id: 7, name: 'campaign-dashboard', displayName: 'Campaign Dashboard',link: '/campaign-dashboard', icon : < Dashboard />},
    
  ];

  const orderedMenuNames = ['profile-matching', 'previous-analysis-report', 'prompts', 'jobde-description', 'users', 'roleprompts','campaign-dashboard'];
  
  return orderedMenuNames
    .map((menuName) => SideMenuList.find((item) => item.name === menuName))
    .filter((item) => item !== undefined)
    .filter((item) => {
      if (roleId === 1 || roleId === 4) return true; 
      if (roleId === 2 || roleId === 3 || roleId === 5 || roleId === 6) {
        if ([2, 6, 4, 3].includes(item.id)) return true; 
        if (item.name === 'campaign-dashboard' && [2, 5].includes(roleId)) return true; 
      }
      return false;
    });
};

const SideMenu = ({ open }) => {
  const location = useLocation();
  const [expandedMenus, setExpandedMenus] = useState({});

  const userRole = localStorage.getItem('userdetails');
  const ParseData = JSON.parse(userRole);

  if (!ParseData || !ParseData.user) {
    return null; // Handle when user details are not available
  }

  const roleId = ParseData.user?.roleId;
  const filteredMenu = getFilteredMenu(roleId, open);

  const handleExpandClick = (menuId) => {
    setExpandedMenus((prevExpandedMenus) => ({
      ...prevExpandedMenus,
      [menuId]: !prevExpandedMenus[menuId],
    }));
  };

  const renderMenuItem = (menu) => {
    const isExpanded = expandedMenus[menu.id] || false;

    // Check if the menu item itself or any of its children are active
    const isActive = (menu.link && location.pathname.startsWith(menu.link)) || 
      (menu.children && menu.children.some((child) => location.pathname.startsWith(child.link)));

    return (
      <div key={menu.id}>
        <ListItem disablePadding>
          <ListItemButton
            component={menu.link ? Link : undefined}
            to={menu.link || ''}
            onClick={() => {
              if (menu.children) {
                handleExpandClick(menu.id);
              }
            }}
            sx={{
              backgroundColor: isActive ? '#1976d2' : 'transparent',
              color: isActive ? '#fff' : 'inherit',
              '&:hover': {
                backgroundColor: '#1976d2',
                color: '#fff',
              },
            }}
          >
            {open ? (
              <ListItemIcon sx={{ color: isActive ? '#fff' : '#1976d2' }}>
                {menu.icon}
              </ListItemIcon>
            ) : (
              <Tooltip title={menu.displayName} placement="right" arrow>
                <ListItemIcon>{menu.icon}</ListItemIcon>
              </Tooltip>
            )}
            <ListItemText primary={menu.displayName} />
            {menu.children ? (isExpanded ? <ExpandLess /> : <ExpandMore />) : null}
          </ListItemButton>
        </ListItem>
        {menu.children && (
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {menu.children.map((child) => {
                const isChildActive = location.pathname.startsWith(child.link);

                return (
                  <ListItemButton
                    key={child.id}
                    component={Link}
                    to={child.link}
                    sx={{
                      pl: open ? 6 : 0,
                      backgroundColor: isChildActive ? '#1976d2' : 'transparent',
                      color: isChildActive ? '#fff' : 'inherit',
                      '&:hover': {
                        backgroundColor: '#1976d2',
                        color: '#fff',
                      },
                    }}
                    onClick={() => setExpandedMenus({ ...expandedMenus, [menu.id]: true })}
                  >
                    <ListItemIcon sx={{ color: isChildActive ? '#fff' : 'inherit' }}>
                      {child.icon}
                    </ListItemIcon>
                    <ListItemText primary={child.displayName} />
                  </ListItemButton>
                );
              })}
            </List>
          </Collapse>
        )}
      </div>
    );
  };

  return <List>{filteredMenu.map((menu) => renderMenuItem(menu))}</List>;
};

export default SideMenu;