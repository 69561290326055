// dev
// const GlobalVariable = Object.freeze({
//     // BASE_API_URL: 'https://f4gkrzagnx.ap-south-1.awsapprunner.com/v1/',
//     BASE_API_URL: 'http://localhost:3000/v1/',
//     BASE_API_GOOGLE_URL: 'https://xvrm22shg6.ap-south-1.awsapprunner.com/'
//     // BASE_API_GOOGLE_URL: 'http://localhost:8000/'

// })

//prod
const GlobalVariable = Object.freeze({
    BASE_API_URL: 'https://dc4nhy46wu.ap-south-1.awsapprunner.com/v1/',
    BASE_API_GOOGLE_URL: 'https://2npirgixjy.ap-south-1.awsapprunner.com/',
})

// const GlobalVariable = Object.freeze({
//     BASE_API_URL: "http://localhost:3000/v1/",
//     BASE_API_GOOGLE_URL: "https://xvrm22shg6.ap-south-1.awsapprunner.com/job_analysis/"
// })


// const GlobalVariable = Object.freeze({
//     BASE_API_URL: 'https://jxyupzqsvp.ap-south-1.awsapprunner.com/',
//     BASE_API_GOOGLE_URL: 'https://jxyupzqsvp.ap-south-1.awsapprunner.com/'
// })

// const GlobalVariable = Object.freeze({
//     BASE_API_URL: 'http://localhost:3000/v1/',
//     BASE_API_GOOGLE_URL: 'http://localhost:3000/v1/'
// })

export default GlobalVariable
